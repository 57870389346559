@import "colors";

#route-container {
	overflow-x: auto;
	height: calc(100% - 20px);
	width: 100%;
}

#route-table {
	> tbody > tr > td {
		padding-left: 4px;

		// Иконка
		&:nth-child(2) {
			width: 28px;
		}

		// Номер
		&:nth-child(3) {
			max-width: 70px;
		}

		&:nth-child(7), // Вид деят-ти
		&:nth-child(8) { // Вид сообщения
			min-width: 16px;
		}

		// Отправление/прибытие
		&:nth-child(9),
		&:nth-child(10) {
			max-width: 100px;
		}
	}
}

.route-details {
	padding: 5px 10px !important;

	.paper-lvl1 {
		padding: 5px 0;
	}
}

// Dispatcher TODO Перенести в _ridership.scss
#routerounds-container {
  position: relative;
  overflow: hidden;
  height: 100%;
  display: block;
}

#routerounds-table-container {
  overflow: auto;
  height: calc(100% - 181px);
}

#routerate-table-container{
  overflow-y: auto;
  height: calc(100% - 147px);
}

#routerate-table,
#routerounds-table{
  cursor: context-menu;

  > tbody > tr {
    > td {
      text-align: center;
    }
  }
}

#routerate-table{
  .tr-selected {
    background-color: #00b0ff;
    color: #ffffff;
  }

  tr {
    &.colored{
      background-color: #DCFFDC;

      &:hover{
        background-color: darken(#DCFFDC, 5);
      }
    }

    > td {
      &:nth-child(2) {
        width: 58px;
      }
    }
  }
  .routerate-td-selected {
    background-color: #00b0ff;
    color: #ffffff;
  }
}

#routerounds-table{
  tr {
    background-color: $white;

    > td {
      white-space: pre-line;

      &:nth-child(2) {
        width: 16px;
      }
      &:nth-child(3) {
        width: 16px;
      }
      &:nth-child(4) {
        width: 100px;
        text-align: left;
      }
      &:nth-child(5) {
        width: 45px;

      }
      &:nth-child(6),
      &:nth-child(7),
      &:nth-child(8),
      &:nth-child(9),
      &:nth-child(10) {
        width: 38px;
      }
      &:nth-child(11) {
        width: 65px;
        text-align: left;
      }
      &:nth-child(12) {
        width: 30px;
        text-align: left;
      }
      &:nth-child(13),
      &:nth-child(14) {
        width: 16px;
      }

      > span {
        display: block;
        width: 16px;
        height: 16px;
      }
    }
    &.working,
    &.waiting {
      background-color: #FFFFC3;
      &:hover {
        color: #000000;
        background-color: darken(#FFFFC3, 10);
      }
    }
    &.advance,
    &.comlete,
    &.delay {
      background-color: #C3FFC3;
      &:hover {
        color: #000000;
        background-color: darken(#C3FFC3, 10);
      }
    }
    &.failed {
      background-color: #FFC3C3;
      &:hover {
        color: #000000;
        background-color: darken(#FFC3C3, 10);
      }
    }
    &.canceled {
      background-color: #DCDCDC;
      &:hover {
        color: #000000;
        background-color: darken(#DCDCDC, 10);
      }
    }
    &.tr-off-monitoring {
      background-color: darken(#DCFFDC, 5);
      &:hover {
        color: #000000;
        background-color: darken(#DCFFDC, 15);
      }
    }
    &.tr-accept-point {
      background-color: #DCFFDC;
      &:hover {
        color: #000000;
        background-color: darken(#DCFFDC, 10);
      }
    }
    &.lunch,
    &.idle {
      background-color: #FFFF00;
      &:hover {
        background-color: darken(#FFFF00, 10);
      }
    }
    &.zeromileage{
      background-color: #DCDCE1;
      &:hover {
        color: #000000;
        background-color: darken(#DCDCE1, 10);
      }
    }
    &.betweenroundidle{
      background-color: #FFFFDC;
      &:hover {
        color: #000000;
        background-color: darken(#FFFFDC, 10);
      }
    }
    &.repair{
      background-color: #CD5C5C;
      &:hover {
        color: #000000;
        background-color: darken(#CD5C5C, 10);
      }
    }
    &.leavingpark{
      background-color: #7FFFD4;
      &:hover {
        color: #000000;
        background-color: darken(#7FFFD4, 10);
      }
    }
    &.arrivepark{
      background-color: #DB7093;
      &:hover {
        color: #000000;
        background-color: darken(#DB7093, 10);
      }
    }
    &.shiftchange{
      background-color: #F0E68C;
      &:hover {
        color: #000000;
        background-color: darken(#F0E68C, 10);
      }
    }
    &.switching{
      background-color: #D7CD73;
      &:hover {
        color: #000000;
        background-color: darken(#D7CD73, 10);
      }
    }
    &.ui-selected, &.ui-selecting  {
      background-color: #00b0ff;
      color: #ffffff;
      &:hover {
        background-color: darken(#00b0ff, 6);
        color: #ffffff;
      }
    }
  }
}

#routerounds {
  width: 60%;
  min-width: 519px;
  height: 100%;
  float: left;

  .top-line{
    line-height: 50px;
    height: 50px;
    font-size: 13pt;
    -moz-user-select: none;
    border-bottom: 1px solid $blue-500;
    box-shadow: 0px 4px 3px -3px $blue-200, 0px 3px 2px -2px $blue-200;

    b {
      padding-left: 25px;
      text-transform: uppercase;
    }
  }


  #rate-info{
    padding: 10px 10px 0 10px;
    height: 38px;
    width: 100%;
    i {
      vertical-align: middle;
    }
  }

  #routerounds-settings{
    padding: 0 10px;
    height: 31px;
    >.left{
      margin: 6px 0;
      >div {
        width: auto;
        display: inline;
      }
    }
  }
}


#routerounds-info,
#routerate-footer{
  border-top: 2px solid $grey-400;
  width: 100%;
  padding: 10px;
  display: table;

  span{
    height: 20px;
    margin: 0 5px;
  }
}

#routeBreaches {
  background-color: #ffffff;
  position: fixed;
  right: 300px;
  left: 300px;
  bottom: 10px;
  height: 200px;
  padding-bottom: 35px;
  z-index: 1000;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  border-radius: 2px;

  .top-line{
    line-height: 50px;
    height: 50px;
    font-size: 13pt;
    -moz-user-select: none;
    border-bottom: 1px solid $blue-500;
    box-shadow: 0px 4px 3px -3px $blue-200, 0px 3px 2px -2px $blue-200;

    b {
      padding-left: 25px;
    }
  }

  #routeBreaches-footer{
    border-top: 2px solid $grey-400;
    width: 100%;
    padding: 10px;
    display: block;

    span{
      margin: 0 5px;
    }
  }
}

#routeBreaches-content {
  overflow: auto;
  height: calc(100% - 56px);
  td {
    padding: 0 5px;
  }
}

#routeBreaches-table {
  width: 100%
}
#routeBreaches-table td{
  overflow: hidden;
  word-wrap: none;
}

.route-points-tab, .route-schedules-tab {
	padding: 0 !important;
    .input-field {
        display: inline-block;
        margin: 0 10px;
        width: 29%;

        .material-icons {
            display: inline;
        }
    }
}

.route-points-table {
	td:nth-child(2){
		width: 1%;
		text-align: right;
		padding-right: 4px;
	}
	td:nth-child(4),
	td:nth-child(5){
		width: 1%;
		padding-right: 8px;
		text-align: right;
	}
}

.route-schedules-table {
	padding: 0;
}

.route-menu {
	position: absolute;
	right: 0;
	top: 0;

	.menu-btn {
		color: $grey-600;
		cursor: pointer;
		padding: 10px 10px;
	}
}

.menu-list {
  padding: 8px 0;
}

#ratesetup-settings{
    padding: 0 20px;
}

#ratesetup-table {
    input{
        border: none;
        max-width: 50px;
        font-size: 11px;
    }
}

.menu-list{
  padding: 8px 0;;
  transition: none;
  list-style-type: none;

  > li > a {
    color: $grey-900;
    line-height: 36px;
    position: relative;
    text-decoration: none;
    font-size: 14px;
    display: block;

    padding: 0 20px 0 50px;
    border-left: 4px solid transparent;

    min-width: 200px;

    &:focus {
      background-color: transparent;
    }

    &:hover {
      color: $grey-900;
      background-color: $hover-color;
    }

    i {
      color: $grey-600;
      position: absolute;
      left: 13px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.ref-rp-container {
	min-height: 256px;
}

.rs-outgr-border {
  td {
    border: solid 1px grey;
  }
}

#ridership-output{
    text-align: center;
    font-size: 11px !important;

    border-collapse: collapse;

    td:nth-child(3){
      text-align: left;
    }

    .holiday {
        background-color: $grey-300;
        opacity: 0.6;
    }
    .cross {
        background-color: $red-300 !important;
        opacity: 0.7;
    }
    .colored {
      background-color: $green-100;
    }

    tr:hover{
        &.colored {
            background-color: $green-200;
        }
        &.holiday {
            background-color: $grey-400;

        }
    }
}

#rs-track-player{
  .track-slider{
    margin: 10px;
  }
  .mb-flat{
    min-width: 40px;
  }
  .track-date{
    display: none;
  }
}

.route-group-table {
  text-align: center;
  th {
    background-color: #eee;
    border-left: 1px solid #999;
    border-right: 1px solid #999;
    text-align: center;
  }
}

.input-number {
  input[type="number"] {
    margin-top: 5px;
  }
}

#sch-offset-table{
  .edit {
    input[type="text"] {
      width: 30px;
      font-size: 11px;
      background-color: transparent;
    }
  }
}

#sch-offset-context-menu {
  .edit {
    display: none;
  }
}

.md-form-content {
  #ep-mileage {
    width: 100px;
    float: right;
  }
}

#routerate-settings{
  width: 100%;
  padding: 5px;
  height: 45px;

  .rr-s-span{
    width: 87%;
    float: left;
    margin: 6px 0;

    .rr-s-ent{
      color: $blue-400;
    }
    .rr-s-date{
      border-bottom: 1px solid $blue-400;
    }
  }
}


#rs-enterpr-group-div {
  width: auto;
}

#chb-curr-ridership {
  width: 130px;
  line-height: 30px;
  margin-left: 10px;
}

.ridership-ondate {
  margin-left: 10px;
}

#ratesetup-table-container {
  overflow-y: auto;
  height: calc(100% - 75px);
}

#ridership-output-container {
  overflow-y: auto;

  height: calc(100% - 90px);

  .tr-click-outgraph {
    background-color: #00b0ff;
  }
}

#ratesetup-table {
  border-collapse: collapse;

  td {
    &:not(:first-child) {
      padding: 0 4px 0 4px;
    }
    border: solid 1px grey;
  }

  .rs-input-td,.rs-driver {
    min-width: 50px;
  }

  .rs-td-wrong{
    border: solid 2px $red-300;
  }
}

#routerounds-info {
  border-top: 2px solid rgb(189, 189, 189);
  display: table;
}

#dp-ridership {
  width: 100px;
}

#rr-search-control {
  padding: 0 5px;
}

#btn-show-rs {
  height: 30px;
}

#btn-enterps-subgroups {
  height: 30px;
  width: 34px;
  padding: 0;
}

#rate-info-one {
  width: 70%;
  >div {
    width: auto;
    display: inline-block;
  }
}
#rate-info-two {
  width: 30%;
  text-align: right;
}

