#way-sheets-container {
  height: 100%;
  width: 100%;
}

#way-sheets-controls {
  padding: 6px 0;
  width: 100%;
}

#way-sheets-group-div {
  width: auto;
  button {
    border-radius: 3px;
  }
}

.way-sheets-tables-container {
  //overflow-x: auto;
  width: 100%;
  height: calc(100% - 48px);
}

.ws-container-overflow-x {
  width: 150%;
  overflow-x: hidden;
  height: 100%;
}

.ws-container-overflow-y {
  height: calc(100% - 52px);
  overflow-y: auto;
}

#head-table-ws {
  border-collapse: inherit;
  height: 52px;
  td {
    &:not(:first-child):not(:last-child){
      &:not(:nth-child(2)) {
        min-width: 70px;
      }
    }
    line-height: normal;
    text-align: center;
    white-space: normal;
  }
}

#way-sheets-table {
  border-collapse: inherit;
  tr {
    td {
      border-bottom: 1px solid grey;
      &:not(:first-child) {
        padding: 3px;
        border-left: 1px solid grey;
        background-clip: padding-box;
        min-width: 70px;
        text-align: center;
      }
      &:nth-child(2) {
        padding: 3px;
        min-width:26px;
      }
      &:last-child {
        border-right: 1px solid grey;
      }
    }
  }

  .ws-td-fillcolor {
    background-color: #dcffdc;
  }

  .ws-td-errcolor {
    background-color: #ffdcdc;
  }

  .td-manualccolor {
    background-color: #dcdcff;
  }

  .ui-selected, .ui-selecting {
    td {
      background-color: $grey-400;
      &:first-child {
        background-color: #337AB7;
      }
    }

    .ws-td-fillcolor {
      background-color: #90d590;
    }

    .ws-td-errcolor {
      background-color: #d9a6a6;
    }

    .td-manualccolor {
      background-color: #9090d5;
    }

    &:hover {
      td {
        background-color: darken($grey-400, 6);
        &:first-child {
          background-color: darken(#337AB7, 6)
        }
      }

      .ws-td-fillcolor {
        background-color: darken(#90d590, 6);
      }

      .ws-td-errcolor {
        background-color: darken(#d9a6a6, 6);
      }

      .td-manualccolor {
        background-color: darken(#9090d5, 6);
      }

    }
  }
}

.tb-ws-summary {
  width: 100%;

  .input-group {
    width: auto;
  }

  th, td {
    padding: 2px;
  }

  th, td:nth-child(2) {
    padding-right: 5px;

  }

  th {
      text-align: center;
  }

  td:first-child {
    width: 180px;
  }

  div {
    float: right;
    min-height: 30px;
  }
}

#fd-way-sheet-tab-motion {
  height: 350px; overflow-y: auto;

  table {
    width: 100%;

    .td-motion-padding {
      padding-right: 15px;
    }
  }
}

.tb-work-units {
  width: 100%;
  td {
    padding: 3px;
  }
}

.catworks-edit-tab {
  border: solid 1px grey;
  width: 100%;
  height: 250px;
}

#tb-ws-works-units {
  border-collapse: collapse;
  tr {
    td {
      border: solid 1px grey;
      &:not(:first-child) {
        padding-left: 5px;

      }
      &.wu-squre-mode {
        padding: 0;
        text-align: center;
      }
    }
  }
}
#ws-color-info {
  > div {
    padding: 5px;
    border: solid 1px grey;
    margin-right: 5px;
  }
  .ws-fill-color {
    background-color: #dcffdc;
  }

  .ws-err-color {
    background-color: #ffdcdc;
  }

  .ws-distinc-color {
    background-color: #dcdcff;
  }
}

.td-fillcolor {
  background-color: #dcffdc;
  border: solid 1px grey;
}

#ws-interval-picker{
  width: auto;
}

.ws-btn-recalc {
  width: 26px;
  height: 26px;
  margin: 16px 0px 0px 8px;
  padding: 0px;
  i {
    width: 22px;
    height: 22px;
    font-size: 22px;
  }
}
/*
#ws-recalc {
  margin-top: 4px;
  margin-right: 20px;
}
*/
#ws-export-excel-div {
  width: auto;
  margin-right: 30px;
}

.export-form {
  width: 100%;
  margin-top: 5px;
}

.aysheets-catalogs {
  padding: 12px 12px 0px 12px;
}