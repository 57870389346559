@import "triggerComposer";
@import "global";
@import "default";
@import "ui";
@import "content";
@import "report";
@import "maininfo";
@import "auth";
@import "way-sheets";
@import "dynamic-report";
@import "ridership";
@import "tachograph";
@import "alerts";
@import "user-guide";
@import "server-monitor";

@import "nis/_nis";
@import "tfm/_tfm";

//mobile version
@media only screen and (max-device-width: 480px) and (orientation : portrait) {
  @import "mobile-device";
}

@media only screen and (max-device-width: 640px) and (orientation : landscape) {
  @import "mobile-device";
}

