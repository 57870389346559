@import "base", "colors";

.default {
	body {
		background-color: $grey-100;
	}
	header {
		position: relative;
		padding-left: 80px;
	}
	footer {
		padding: 16px 0 16px 80px;
		color: $grey-500;
	}
}

.content-background {
	width: 100%;
	top: 0;
	position: absolute;
	height: 64px;
	@include transition(all 0.4s ease 0s);
}

.expanded {
	.content-background {
		height: 400px;
	}
}

.page-caption {
	a, a:hover, a:focus, a:visited {
		color: #ffffff;
		text-decoration: none;
		font-size: 20px;
		line-height: 64px;
	}
}
.page-subcaption {
	color: #ffffff;
	font-size: 14px;
	line-height: 48px;
}
.topbar-links {
	float: right;
	a, a:hover, a:focus, a:visited {
		padding: 0px 24px;
		font-size: 14px;
	}
}

.content-page {
	@include shadow(2);
	background-color: #ffffff;
	margin-top: 16px;
	width: 832px;
	min-height: 460px;
	position: relative;
	padding: 64px;
}

.content-page, .floating-cards .content {
	h1, .h1 {
		font-size: 34px;
		font-weight: 400;
		line-height: 40px;
		margin: 0px 0px 26px;
	}

	h2, .h2 {
		font-size: 24px;
		font-weight: 500;
		line-height: 32px;
		margin: 36px 0px 4px;
	}

	h3, .h3 {
		font-size: 24px;
		font-weight: 400;
		line-height: 32px;
		margin: 36px 0px 4px;
	}

	ol {
		margin: 4px 0px 12px;
		padding: 0px;
		li {
			margin: 4px 0px 4px 20px;
		}
	}

	img {
		max-width: 100%;
	}
}


.floating-cards {
	position: relative;
	margin-top: 16px;
	padding: 0;

	> div {
		left: 50%;
		transform: translateX(-50%);
		position: relative;
		width: 832px - 32px;
		padding: 24px;
		background-color: #ffffff;
		overflow: hidden;
		@include shadow(1);
		@include transition(all 0.4s ease 0s);
	}

	.content {
		img {
			margin: 16px 0;
		}
	}
}

html:not(.expanded) {
	.floating-cards {
		> div {
			overflow: hidden;
			min-height: 100px;
			max-height: 2000px;
			margin: 1px 0 0 0;
			cursor: pointer;

			&:first-child {
				border-radius: 2px 2px 0 0;
			}
			&:last-child {
				border-radius: 0 0 2px 2px;
			}

			&:hover {
				width: 832px;
				@include paper(2);
				margin: 16px 0;
			}
		}
	}
}

.expanded {
	.floating-cards {
		> div:not(.expand) {
			min-height: 0;
			max-height: 0;
			padding-top: 0;
			padding-bottom: 0;
			//margin: 0;
			opacity: 0;
		}

		> div.expand {
			width: 832px;
			min-height: 460px;
			padding: 64px;
			margin-bottom: 30px;
			.device-description {
				margin-bottom: 16px;
			}
		}
	}
}


.paper-left .content-page {
	margin: 16px 0 0 128px;
}
.paper-center {
	.content-page, .floating-cards {
		margin-left: auto;
		margin-right: auto;
	}
}

.device-description {
	height: 70px;
	@include transition(all 0.2s ease 0s);
}

.device-preview {
	max-width: 130px;
	max-height: 70px;
	float:right;
	top: 50%;
	position: relative;
	transform: translateY(-50%);
	@include transition(all 0.2s ease 0s);
}
.device-name {
	font-size: 22px;
	font-weight: 400;
	@include transition(all 0.2s ease 0s);
	margin: 0;
}
.device-distr {
	margin: 0;
}

.expand {
	.device-description {
		height: 100px;
	}

	.device-preview {
		max-width: 160px;
		max-height: 100px;
	}
	.device-name {
		font-size: 28px;
	}
}