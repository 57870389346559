$nis_colors: (
    '#ffffff',    //1
    '#000000',
    '#008a93',
    '#eeeeee',
    '#CE93D8'
);

@function get-color($index) {
  @return unquote(nth($nis_colors, $index));
}