#gen-event-tr-com {
  .left-float {
    margin-left: 0px;
  }
}

#addAlertDialog {
  .input-group-addon {
    border-radius: 4px;
    border: 1px solid #ccc;
  }
}

.master-alert-label {
  label {
    font-size: 16px;
    margin-left: 3px;
  }
}

#master-alert-mobjects {
  width: 55%;
  border: 1px solid grey;
  min-height: 400px;
}
#master-alert-pattern-settings {
  width: 40%;
  .pattern-settings {
    height: 400px;

    #master-alert-zones {
      overflow-y:auto;
      height: calc(100% - 24px);
    }
  }
}



#master-alert-add-action {
  display: inline-table;
  width: 100%;
}

#master-alert {
  padding: 17px 17px 0px 17px;
}

#alert-his-content {
  height: calc(100% - 100px);
}

#master-alert .tab-pane{
  padding: 5px 3px;
  height: 350px;
  overflow-y: auto;
}

.trigger-list {
  max-height: 200px;
  overflow-y: auto;
  width: 100%;
}