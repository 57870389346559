#dynamic-report-container {
  height: 100%;
}

#dynamic-report-tabs {
  padding: 0;
  height: 100%;
}

#dynamic-report-container label.control-label {
  padding-top: 7px;
}

#dr-span-title {
  line-height: 45px;
  font-weight: bold;
  font-size: 20px;
}

#drForm {
  height: calc(100% - 40px);
  overflow-y: auto;
}

#dr-first-component {
  width: 100%;

  #dr-controls {
    #chb-dr-isuser {
      margin-right: 20px;
    }

    button {
      margin-top: 5px;
    }
  }
}

#dr-second-component {
  div:not(:last-child) {
    margin-right: 10px;
  }
}

#dr-tb-content {
  margin-top: 15px;
  width: 100%;
}

.dr-tree-container {
  width: 100%;

  background-color: #ffffff;
  border: 1px solid #cecfcf;

  #dr-bookmarks-tree{
    max-height: 400px;
  }
}

.dr-bookmark-table .ui-selected {
  background-color: #054A81;
  color: #ffffff;
}

.dr-bookmark-table td div {
  width: 97px;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  overflow-y: hidden;
}

.dr-bookmark-table tr input {
  width: 97px;
}

.div-bm-table {
  width: 100%;
  height: 400px;
  border: solid 1px #cecfcf;
}

.dr-table-overflow {
  width: 100%;
  overflow-y: auto;
  //padding-top: 3px;
  height: calc(100% - 20px);

  #dr-selected-bookmarks {
    width: 100%;
    tr {
      td:last-child {
        width: 150px;
        input {
          width: 100%;
          border: solid 1px #cecfcf;
        }
      }
    }

  }
}

.dr-handle {
  background-color: #eeeeee;
}

.dr-report-tab {
  overflow: auto;

  h3 {
    text-align: center;
  }
}

.dr-result-header {
  width: 100%;
  margin-bottom: 10px;
  font-weight: bold;

  span {
    font-weight: normal;
  }
}

.dr-result-table {
  th {
    background-color: #d3d3d3;
    text-align: center;
    font-weight: bold;
  }

  .dr-result-total {
    background-color: #d3d3d3;
  }

  .dr-result-total:first-child {
    font-weight: bold;
  }
}

#dr-table-content {
  //height: calc(100% - 63px);
  //height: auto;
  //overflow-y: auto;
  width: 100%;
  > tbody {
    > tr {
      >td {
        padding: 7px;
      }
      > td:not(:nth-child(2)) {
        vertical-align: super;
      }
      > td:first-child {
        width: 40%;
      }
      > td:last-child {
        width: 55%;
      }
      > td:nth-child(2) {
        width: 5%;
      }
    }
  }
}

.bm-table-span-row {
  width: 16px;
  height: 16px;
  display: block;
  background-image: url("../img/icons/check.svg");
  background-size: cover;
}

.bm-table-span-row-check {
  background-image: url("../img/icons/check_blue.svg");
  background-size: cover;
}

#head-dr-slcbm-table {
  tr {
    td:nth-child(2) {
      width: 24px;
      span {
        margin-left: 2px;
      }
    }
  }
}

#div-clear-slcbm-table{
  position: relative;
  bottom: 60px;
  right: calc(-100% + 80px);

  #btn-clear-sclbm-table{
    width: 50px;
    height: 50px;
  }
}

#dr-add-marker {
  display: inline;
  top: 50%;
  position: relative;
  width: 45px;
  height: 45px;
  background: url("../img/icons/transfer.svg") no-repeat;
  background-size: cover;

  &:focus {
    outline:0;
  }
}

.dr-close-tab {
  cursor: pointer;
}

#dynreport-res-name {
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  height: 30px;
}

#dynreport-res-create-date {
  width: 100%;
  height: 20px;
  margin-bottom: 10px;
}

#dynreport-res-overflow-table {
  height: calc(100% - 60px);
  overflow-y: auto;
}

#driver-table-container {
  height: calc(100% - 20px);
  overflow-y: auto;
}

.dynreport-content-row {
  display: table;
  width: 100%;
  margin-bottom: 12px;
  > div {
    &:first-child {
      width: 40%;
    }
    &:last-child {
      width: 50%;
    }
  }
}