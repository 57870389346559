.trigger-composer{
    padding-top: 0;
    width: 100%;
}

.trigger-composer ul.dropdown-menu{
    font-size: 10px;
    padding: 2px 0;
}

.trigger-composer ul.dropdown-menu a{
    padding: 1px 5px;
}

.trigger-header{
    display: block;
}

.trigger-menu{
    width: 35%;
}

.trigger-menu button{
    font-size: 7px;
    padding: 3px 6px;
}

.trigger-menu .selected{
    background-color: #31b0d5;
    color: #fff;
}

.trigger-list ul{
    padding: 0 0 0 10px;
}

.trigger-list li{
    background-image: none !important;
    padding-left: 0;
}

.trigger-list li label{
    margin: 0;
    padding: 0;
    font-weight: normal;
}

.trigger-settings{
    width: 63%;
    min-height: 240px;
    padding: 5px;
    border-left: 1px solid #aaaaaa;
    padding-top: 0px;
}

.trigger-settings .tf-settings{
    width: 100%;
}

.trigger-settings .tf-state{
    padding: 0;
}

.trigger-settings label{
    margin: 0;
}

.trigger-settings .tf-list{
    margin-top: 10px;
}

.trigger-settings .tf-list ul{
    margin: 0;
}

.trigger-settings .tf-list li{
    list-style-type: none;
}

.trigger-settings .tf-list input[type="checkbox"]{
    margin: 0;
}

.trigger-settings .tf-min-value, .trigger-settings .tf-max-value{
    margin: 3px 2px;
}

.trigger-settings .date{
    width: 95px;
}
