@import "colors";

//Оформление мониторинга

#server-monitor-container {
  h1 {
    text-align: center;
  }
  #charts-status-period {
    margin-left: 5px;
  }
  #server-logs {
    margin-top: 20px;
  }
}

#monitoring {
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#monitoring td, #monitoring th {
  border: 1px solid #ddd;
  padding: 6px;
}

#monitoring tr:nth-child(even) {
  background-color: #f2f2f2;
}

#monitoring tr:hover {
  background-color: #ddd;
}

#monitoring th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #337ab7;
  color: white;
  height: 50px;
}

