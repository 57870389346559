@import "base";

#tachograph-obj-container {
  height: calc(100% - 36px);

}

#tachograph-controls {
  background-color: white;
  width: 100%;
  padding: 2px 8px;
  box-shadow: 0 4px 3px -3px rgba(0, 0, 0, 0.12), 0 3px 2px -2px rgba(0, 0, 0, 0.24);

  button {
    margin: 7px 0 0 7px;
    min-width: 36px;
    height: 36px;
  }
  #tach-btn-help {
    cursor: help;

    i {
      vertical-align: middle;
    }
  }
}

#tachograph-intervals {
  height: calc(100% - 95px);
}

#tachograph-chartpanel {
  height: calc(100% - 49px);
  padding-top: 10px;
}

#tachograph-period {
  padding: 0px;
  height: auto;
}

#tach-dd-help {
  table {
    line-height: normal;
    width: 350px;
    border-collapse: collapse;
    tr {
      td {
        padding: 0 5px 5px 0;
        &:first-child {
          width: 30px;
        }
        span {
          width: 24px;
          height: 24px;
          margin: 5px;
          display: block;
        }
      }
      &:not(:last-child) {
        border-bottom: solid 1px #d3d3d3;
      }
    }
  }
}

.info-driving {
  padding: 2px;
  background-color: rgb(242,145,50);
}
.info-ready {
  background-color: rgb(239,225,137);
}
.info-work {
  padding: 2px;
  background-color: rgb(184,220,114);
}
.info-break {
  padding: 2px;
  background-color: rgb(61,196,200);
}
.info-without-card {
  padding: 2px;
  background-color: rgb(36,83,127);
  color: #ffffff;
}

.info-nodata {
  padding: 2px;
  background-color: rgb(211,211,211);
}


.tachograph-tools {
  padding: 7px;
}

#tach-selected-obj {
  cursor: pointer;
  label, input, hr {
    cursor: inherit;
  }
}

#tach-intervals-info {
  .focused {
    background-color: #337AB7 !important;
    color: #ffffff;
  }
}

#tach-dd-total {
  padding: 8px;
  table {
    border-collapse: separate;
    border-spacing: 0 5px;
    line-height: normal;
    tr {
      td {
        padding: 5px;
        &:first-child {
          width: 70px;
          border: solid 1px grey;
          text-align: center;
        }
      }
    }
  }
}

// tahograph
.dddfiles-tools {
  padding: 0 7px 7px 7px;
}

#dddfiles-tables {
  height: calc(100% - 52px);
}

#dddfiles-info-tabs {
  .tab-dddfile {
    height: calc(100% - 40px);
  }

  .tab-ddd-container {
    width: 49%;
    height: 100%;
    label {
      margin: 0;
    }
  }
}

.ddd-overflow-table {
  overflow-y: auto;
  height: calc(100% - 40px);
}


#ddd-vehicles-table {
  height: 40px;
  tr {
    td {

      &:nth-child(2), &:nth-child(3){
        width: 120px;
      }
      &:nth-child(6){
        width: 90px;
      }

      &:nth-child(4),&:nth-child(5) {
        width: 100px;
      }

      //text-align: center;
      white-space: normal;
    }
  }
}

#dddfiles-info-ul {
  li:hover {
    background-color: rgba(153, 153, 153, 0.2);
  }
}

#tab-ddd-driver-activity {
  #ddd-licensedr-info {
    height: 40%;
  }

  .tab-ddd-license-container {
    height: 100%;
    width: 39%;
    label {
      margin: 0;
    }
  }

  .tab-ddd-activity-container {
    height: 100%;
    width: 59%;
    label {
      margin: 0;
    }
  }

  #ddd-license-date-activity {
    height: calc(60% - 20px);
    overflow-y: auto;

    #date-activity-table {
      width: 100%;
      td {
        @include no-select;
        padding: 2px;
        cursor: pointer;
        text-align: center;
        font-size: 11px;

        &:hover{
          background-color: $hover-color;
        }
        &.selected {
          background-color: #337AB7;
          color: #ffffff;
        }
      }
    }
  }
}


#ddd-driveractivity-table {
  tr {
    &.focused {
      background-color: #337AB7 !important;
      color: #ffffff;
    }
  }
}

