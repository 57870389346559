// Базовые стили элементов навигации не относящиеся к конкретному модулю
@import "base", "colors";

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
  @include no-select;
}

.application {
  body {
    overflow: hidden;
    height: 100%;
  }
}

.secondary-text {
  color: $grey-400;
  font-size: 10px;
}

// Paper

@for $i from 1 through 5 {
  .paper-lvl#{$i} {
    @include paper($i);
  }
}

@for $i from 1 through 5 {
  .shadow-lvl#{$i} {
    @include shadow($i);
  }
}

.bottom-shadow {
  @include shadow-bottom;
}

.paper-content {
  padding: 30px;
}

.paper-footer {
  text-align: right;
  padding: 8px;

  button {
    margin: 0 0 0 2px;
  }
}

.primary-bgr {
  input[type="text"],
  input[type="password"]{
    background-color: transparent;
  }
}

.overlay {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: $overlay_z;
  opacity: 0;
  background-color: #000000;
  @include transition(opacity 0.2s linear 0s);
  &.dark { opacity: .3; }
}

/* Top */
.application header {
  @include shadow(2);
  position: absolute;
  top: 0;
  height: 50px;
  line-height: 50px;
  left: 0;
  right: 0;
  background-color: #222;
  z-index: 2;
  overflow: hidden;

  a {
    text-decoration: none;
    color: $grey-200;

    &:focus, &:hover {
      text-decoration: none;
      color: #FFF;
    }
  }

  .brand {
    height: 50px;
    padding: 0 4px;
    float: left;

    >img {
      max-height: 40px;
      max-width: 90px;
    }
  }

  .navbar-text {
    float: left;
    font-size: 13px;
    color: $grey-200;
    margin: 0 15px;
  }

  .badge {
    display: inline-block;
    min-width: 40px;
    padding: 3px 7px;
    font-size: 12px;
    font-weight: 700;
    line-height: 1;
    color: #FFF;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    @include border-radius(10px);
    border: 1px solid $grey-200;
    background-color: transparent;
  }

  .material-icons {
    vertical-align: middle;
    padding-right: 4px;
  }
}

$leftwidth: 572px;  // 450

/* Left panel */
#left-panel {
  display: block;
  position: absolute;
  left: 0;
  top: 50px;
  width: $leftwidth;
  bottom: 0;
  //overflow: hidden; // Не отображаются контекстные меню
  @include shadow(2);

  > .page-content {
    //height: calc(100% - 91px);
    width: 100%;
    display: none;
    //overflow-y: auto;
    position: absolute;

    &.active {
      display: block;
    }
  }
}

#page-head {
  position: relative;
  z-index: 1;
  @include shadow-bottom;

  &.no-shadow {
    box-shadow: none;
  }

  .top-line {
    line-height: 50px;
    height: 50px;
    font-size: 13pt;
    @include no-select;
    overflow: hidden;

    > i {
      cursor: pointer;
      padding: 13px 10px;
    }

    #page-title {
      float: left;
      padding-left: 10px;
    }

    .arrow-dropdown {
      color: inherit;
      padding-left: 2px;
    }

    .page-action {
      float: right;
      font-size: 10pt;
      padding:0 16px;
      cursor: pointer;
    }
  }

  .head-content {
    display: none;

    &.active {
      display: block;
    }
  }
}

/* Search */
#search-panel {
  position: absolute;
  top: 7px;
  left: 7px;
  right: 7px;
  height: 37px;
  line-height: 37px;
  display: none;
  padding: 6px;
  @include paper;

  input[type="text"] {
    width: 100%;
    height: 100%;
    padding: 0;
    background: transparent;
    float: left;
    color: $grey-900;
  }

  i {
    color: $grey-500;
    cursor: pointer;
  }
}

#search-text-cont {
  position: absolute;
  left: 37px;
  right: 33px;
  top: 7px;
  bottom: 5px;
  input {
    line-height: normal;
  }
}

#search-close, #search-next, #search-prev {
  float: right;
}

/* Page Menu */

#page-select-menu {
  width: 240px;
}

/* Tabs  and scrolling*/
.head-tabs {
  position: relative;

  .hidden-scroll-nav-tabs {
    overflow-x: hidden;
    margin: 0px;
  }

  .div-scroll-nav-tabs {
    display: inline;
    @media screen and (-webkit-min-device-pixel-ratio:0)
    {
      float: left;
    }
    left: 0;
    position: relative;
    @include transition(left 0.1s linear 0s);
  }

  i {
    @include no-select;
    cursor: pointer;
    position: absolute;
    display: none;
    line-height: 41px;
  }
  .prev-nav-tab {
    left: 0px;
  }
  .next-nav-tab {
    right: 0px;
    top: 0px;
  }
}

.overflowed-tabs {
  i {
    display: block;
  }

  .hidden-scroll-nav-tabs {
    margin: 0px 24px;
  }
}

.menu-tabs {
  padding: 0;
  height: 38px;
  width: 100%;
  display: table;

  > li {
    display: table-cell;
    text-align: center;
    position: relative;
    vertical-align: middle;
    width: 1%;
    cursor: pointer;
    font-weight: 500;
    padding: 0 12px;
    text-transform: uppercase;

    opacity: 0.7;

    &.active {
      opacity: 1;
    }
  }
}

.tab-container > div {
  display: none;
  height: 100%;
  width: 100%;
  position: absolute;

  &.active {
    display: block;
  }
}

.page-white {
  background-color: #fff;
}

.page-scroll {
  overflow-y: auto;
}

.tab-indicator {
  position: relative;
  height: 3px;
  @include transition(left 0.2s ease 0s, width 0.2s ease 0s);
}

/* Side menu */
#side-menu {
  position: absolute;
  left: -403px;
  top: 0;
  width: 400px;
  bottom: 0;
  z-index: $overlay_z+1;
  background-color: #fff;
  @include shadow(2);
  @include transition(left 0.2s ease 0s);

  &.opened { left: 0; }
}

$side-head-height: 100px;

#side-head {
  height: $side-head-height;
}

#side-menu-items {
  overflow-y: auto;
  height: calc(100% - #{$side-head-height});

  > hr {
    margin: 8px 0;
    border: none;
    border-top: 1px solid $separator-color;
    height: 0;
    box-sizing: content-box;
  }
}

.menu-item {
  height: 48px;
  line-height: 48px;
  display: block;
  font-size: 13pt;
  padding: 0 12px;

  &:hover { background-color: $grey-100; }

  .material-icons {
    vertical-align: middle;
    padding-right: 19px;
    color: $grey-600;
  }

  span {
    vertical-align: middle;
  }
}

.logo-company {
  padding:8px 16px 0 16px;
  font-size:12pt;
  height: 57px;
  img {
    margin-right: 8px;
    margin-top: 5px;
    max-height: 42px;
    max-width: 90px;
  }
}

/* App Content */
.application main {
  position: absolute;
  left: $leftwidth;
  top: 50px;
  right: 0;
  bottom: 0;

  &.full-main {
    width: 100% !important;
    display: block !important;
    left: 0 !important;
  }

  > div {
    height: 100%;
    display: none;

    &.active {
      display: block;
    }
  }
}

/* Button */

.mb-raised {
  height: 36px;
  min-width: 64px;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  text-transform: uppercase;

  padding: 0 16px;
  border: 0 none;
  border-radius: 2px;
  overflow: hidden;

  background-color: $grey-300;
  color: $grey-900;
  @include shadow;

  &:active {
    background-color: $grey-400;
  }

  @include transition(all 0.2s ease 0s);

  //&:hover,
  &:active {
    @include shadow(2);
    z-index: 1;
  }

  &.flat {
    @include shadow(0);
    &:hover {
      @include shadow(1);
    }
    &:active {
      @include shadow(2);
    }
  }

  &.dens {
    height: 32px;
    font-size: 13px;
  }

  &.colored {
    // _theme.scss
  }

  &:disabled {
    color: rgba(#000000, 0.26) !important;
    background-color: rgba(#000000, 0.12) !important;
    @include box-shadow(none !important);
  }
}

.mb-flat {
  min-width: 64px;
  height: 36px;

  font-size: 14px;
  font-style: normal;
  text-transform: uppercase;

  padding: 0 8px;
  border: 0 none;
  border-radius: 2px;
  overflow: hidden;

  background-color: transparent;

  @include transition(all 0.2s ease 0s);

  &:hover, &:active {
    background-color: $hover-color;
  }

  &.dens {
    height: 32px;
    font-size: 13px;
  }
}

.mb-icon {
  padding: 8px;
  height: 40px;
  width: 40px;
  background-color: transparent;
  border-radius: 50%;
  border: 0 none;
  @include transition(all 0.2s ease 0s);
  display: block; // Для <a>
  &:hover, &:active {
    background-color: $hover-color;
  }
}

/* Input field */

.input-field {
  position: relative;
  line-height: normal;
  min-height: 40px;
  @include no-select;

  input[type="text"],
  input[type="password"]{
    font-size: 13px;
    outline: none;
    border: 0;
    border-bottom: 1px solid $grey-300;
    transform: translate3d(0, 0, 0);
    width: 100%;
    height: 2rem;
    background: transparent;
    margin: 0;
    padding: 0;
    text-overflow: ellipsis;
  }

  label {
    font-weight: 400;
    color: #9E9E9E;
    font-size: 0.8rem;
    display: block;
    margin: 0;
    padding-top: 4px;
  }

  .float-la {
    font-weight: 400;
    color: #9E9E9E;
    position: relative;
    top: 20px;
    padding: 0px;
    font-size: 1rem;
    height: 17px;
    cursor: text;
    transition: transform 0.2s ease 0s;
    -webkit-transition: -webkit-transform 0.2s ease 0s;
  }

  &.fill .float-la {
    font-size: 0.8rem;
    @include transform(translateY(-16px));
  }

  .error-text {
    height: 0;
    overflow: visible;
    display: block;
    font-size: 0.8rem;
    color: $red-500
  }

  hr {
    border: none;
    width: 100%;
    position: relative;
    top: -1px;
    margin: 0;
    @include transform(scale(0));
    transition: transform 200ms linear;
    -webkit-transition: -webkit-transform 200ms linear;
  }

  input:focus+hr {
    transform: scale(1);
    -webkit-transform: scale(1);
  }

  .switcher, .md-checkbox {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
  }

  &.loading {
    .switcher, .md-checkbox {
      display: none;
    }

    &:after {
      content: "";
      background: url('/img/loading.gif');
      width: 24px;
      height: 24px;
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
    }
  }

  &.checkbox {
    cursor: pointer;
    margin: 0; // Bootstrap fix
    margin-right: 5px;
    span {
      line-height: 40px;
      display: block;
      border-right: solid 24px transparent;
      @include no-wrap();
    }
  }
}

.action-field {
  display: table;

  > .input-field {
    display: table-cell;
    width: 100%;
  }

  > .action-field-item {
    display: table-cell;
    vertical-align: middle;
    padding-left: 8px;

    > .mb-icon {
      position: relative;
      bottom: -12px;
      color: $grey-600;

      &:hover {
        color: $grey-900;
      }
    }
  }
}

.input-field-row {
  display: flex;
  flex-direction: row;
  width:100%;

  > .input-field {
    flex-grow: 1;
    width: 0;

    &:not(:last-child) {
      margin-right: 16px;
    }
  }
}

input[type="checkbox"]:checked + .switcher {
  .thumb { left: 19px; }
}

.switcher {
  width: 37px;
  height: 20px;
  cursor: pointer;
  position: relative;
  display: inline-block;

  .thumb {
    position: absolute;
    width: 20px;
    height: 20px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
    border-radius: 50%;
    background-color: $grey-50;
    left: -2px;
    top: 0;
    transition: left 0.3s ease 0s, background 0.3s ease 0s;
  }

  .track {
    transition: background-color 0.3s ease 0s;
    background-color: #B9B9B9;
    height: 14px;
    border-radius: 8px;
    position: absolute;
    top: 4px;
    width: 37px;
  }
}

$chb_on: 24px;
$chb_off: 18px;

input[type="checkbox"]:checked + .md-checkbox {
  .unchecked {
    //font-size: $chb_off;
    opacity: 0;
  }
  .checked {
    font-size: $chb_on;
    opacity: 1;
  }
}

.md-checkbox {
  position: relative;
  cursor: pointer;
  width: 24px;
  height: 24px;
  @include no-select;

  i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    display: block;
    @include transition(all 0.3s ease 0s);
  }
  .unchecked {
    //font-size: $chb_on;
    opacity: 1;
  }
  .checked {
    font-size: $chb_off;
    opacity: 0;
  }
}

.floating-la input {
  margin-top: 15px;
}

/* Select field */

// Поле ввода с текущим элементом
.select-dropdown {
  cursor: pointer;
  border: 0;
  border-bottom: 1px solid $grey-300;
  @include no-select;
  padding-right: 24px !important;
}

// Иконка открытия меню
.dropdown-icon {
  color: $grey-400;
  //float: right; // Не нужно, т.к. position: absolute
  position: absolute;
  right: 0;
  //bottom: 0;
  @include no-select;
  cursor: pointer;
}

// Выпадающее меню
.dropdown-content {
  @include paper;
  position: fixed;
  transition: none;
  list-style-type: none;
  margin: 0;
  padding: 8px 0;
  z-index: $overlay_z+1;
  overflow: auto;

  max-height: 500px;
  min-width: 60px;
  display: none;

  //max-height: 0;
  //opacity: 0;
  //visibility: hidden;

  &.expand {
    @include transition(opacity 0.2s ease 0s, max-height 0.4s ease 0s);
    max-height: 500px;
    overflow-y: auto;
    opacity: 1;
    visibility: visible;
  }

  li {
    padding: 0 16px;

    line-height: 32px;
    font-size: 13px;
    font-weight: 400;

    cursor: pointer;
    white-space: nowrap;

    &:hover {
      background-color: $hover-color;
    }

    &.branch {
      height: auto;
      min-height: 32px;
      padding: 0 48px 0 24px;
    }

    i {
      vertical-align: middle;
      padding-right: 12px;
      color: $grey-600;
    }
  }

  &.tree li {
    padding: 0 48px 0 24px;
  }

  &.dropdown-checkbox {
    i {
      visibility: hidden;
    }

    li {
      padding-left: 12px;
    }

    .selected > i {
      visibility: visible;
    }
  }

  &.large {
    li {
      line-height: 48px;
      font-size: 16px;
    }
  }
}

// Bootstrap menu
// TODO Избавиться от этого варианта меню. Использовать наш dropdown-component
.dropdown-menu {
  //z-index: 9999;
  z-index: $overlay_z - 1;
  @include paper(2);
  padding: 8px 0;

  display: none;
  max-height: 500px;
  overflow-y: auto;
  //background-color: $grey-100;
  //@include transition(opacity 250ms ease-out, max-height 250ms ease-out);

  > li > a {
    color: $grey-900;
    line-height: 36px;
    font-size: 13px;
    position: relative;
    cursor: pointer;

    padding: 0 20px 0 50px;
    border-left: 4px solid transparent;

    min-width: 200px;

    &:focus {
      background-color: transparent;
    }

    &:hover {
      //padding-left: 50px;
      //border-left: 4px solid $grey-600;
      color: $grey-900;
      background-color: $hover-color;
    }

    i {
      color: $grey-600;
      position: absolute;
      left: 13px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .divider {
    margin: 6px 0;
    background-color: $separator-color;
  }

  &.large {
    > li > a {
      line-height: 48px;
      font-size: 16px;
    }
  }
}

.dropdown-submenu {
  position: relative;

  > .dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: -6px;
    margin-left: -1px;
    -webkit-border-radius: 0 6px 6px 6px;
    -moz-border-radius: 0 6px 6px 6px;
    border-radius: 0 6px 6px 6px;
  }

  > a:after {
    display: block;
    float: right;
    width: 0;
    height: 0;
    margin-top: 5px;
    margin-right: -10px;
    border-color: transparent;
    border-left-color: #cccccc;
    border-style: solid;
    border-width: 5px 0 5px 5px;
    content: " ";
  }

  &:hover{
    &> .dropdown-menu {
      display: block;
    }
    &> a:after {
      border-left-color: #ffffff;
    }
  }
}

/* Input row */

.input-row {
  display: inline-block;
}

// Red

.mb-red {
  color: $red-500 !important;
}

.if-red {
  label {
    color: $red-500 !important;
  }

  hr {
    border-bottom: 2px solid $red-500 !important;
  }
}

// Не используется. Доделать datetimepicker
.err-date-time hr {
  transform: scale(1);
  border-bottom: 2px solid $red-500 !important;
}

// JQuery-UI
.message-box {
  .ui-dialog-titlebar {
    background-color: #ffffff;
    color: $grey-900;
    font-size: 20px;
    padding: 24px 24px 20px 24px;
  }
  .ui-dialog-title {
    margin: 0;
    white-space: normal;
    width: 100%;
  }
  .ui-dialog-content {
    padding: 0 24px 24px 24px;
    color: $grey-900;
    font-size: 14px;
    width: 100% !important;
  }

  &.notitle {
    .ui-dialog-titlebar {
      padding: 0;
    }
  }
}

.ui-selectmenu-menu {
  position: fixed;
}

.no-pad-dialog {
  .ui-dialog-content {
    padding-left: 0;
    padding-right: 0;
  }
}

.no-title-dialog {
  .ui-dialog-titlebar {
    display: none;
  }
}

// Image Preview
.preview-img {
  cursor: pointer;
}
.preview-container {
  background-color: #ffffff;
  position: fixed;
  z-index: $overlay_z+1;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%,-50%,0);
  overflow: auto;
  max-height: 100%;
  max-width: 100%;
}

// Form

.md-form-content {
  //$line-height: 45px;
  overflow-y: auto;

  > .input-field,
  > .action-field,
  > .input-field-row {
    margin: 14px 0;
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.page-form {
  padding: 16px;
}

// Snackbar
.snackbar {
  @include paper(1);
  background-color: #323232;

  position: fixed;
  z-index: $overlay_z - 1;

  min-width: 288px;
  max-width: 568px;
  line-height: 48px;
  bottom: -50px;
  left: 50%;
  transform: translateX(-50%);

  padding: 0 24px;
  font-size: 14px;

  cursor: default;

  @include transition(bottom 0.2s ease);

  p {
    display: inline;
    margin: 0;

    color: #ffffff;
    text-shadow: 0 0 1px #000000;
  }

  span {
    float: right;
    margin-left: 48px;
    text-align: right;
    color: #c6ff00;
    cursor: pointer;
  }

  &.active {
    bottom: 0;
  }
}

// Lists

.md-list {
  > li {
    height: 48px;
    line-height: 48px;
    overflow: hidden;

    &.card-title {
      padding-left: 12px;
      font-size: 13pt;
    }

    &:not(:last-child) {
      border-bottom: 1px solid $separator-color;
    }
  }
}

.card-list {
  overflow-y: auto;
  background-color: $grey-200;
  height: 100%;
  min-height: 48px;

  > ul {
    margin: 18px;
    @include paper;

    > li {

      p {
        float: left;
      }

      input {
        line-height: 2em;
        width: calc(100% - 118px);

        &:focus {
          border-bottom: 1px solid $grey-400;
        }
      }

      .list-icon {
        padding: 12px;
        float: left;
      }

      .list-action {
        font-size: 12pt;
        color: $grey-600;
        float: right;
        line-height: 48px;
        padding-right: 12px;
        cursor: pointer;
      }
    }
  }

  .card {
    @include paper;
    margin: 18px;
  }
}

.list-item-action {
  display: table;
  table-layout: fixed;

  width: 100%;
  height: 56px;
  @include no-select;

  > .item-action {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;

    width: 56px;
    color: $grey-600;

    @include transition(all 0.2s ease 0s);

    &.item-menu {
      color: $grey-400;

      &:hover {
        color: $grey-600;
      }
    }
  }

  > .item-text {
    display: table-cell;
    vertical-align: middle;
    font-size: 15px;
  }
}

.list-compact {
  >.list-item-action {
    height: 48px;

    > .item-action {
      width: 48px;
    }

    > .item-text {
      font-size: 14px;
    }
  }
}

.list-dens {
  >.list-item-action {
    height: 36px;

    > .item-action {
      width: 36px;
    }

    > .item-text {
      font-size: 13px;
    }
  }
}

.card-list-controls {
  text-align: center;
  padding: 16px;
}

.expandable {
  > .list-item-action {
    > .expand-indicator {
      @include transition(all 0.2s ease 0s);
    }
  }

  > .expand-content {
    max-height: 0;
    overflow: hidden;
    @include transition(max-height 0.4s ease 0s);
  }

  &.expand {
    > .list-item-action {
      > .expand-indicator {
        @include transform(rotate(-180deg))
      }
    }

    > .expand-content {
      //max-height: 1000px;
      max-height: 100%;
    }
  }

  .expand-title {
    cursor: pointer;
  }
}

.md-form-content > .expandable {
  > .expand-content {
    border-bottom: 1px solid $separator-color;
  }

  &.expand > .expand-content {
    border-top: 1px solid $separator-color;
  }
}

// Virtual Tables
.virt-table-container {
  overflow-y: auto;
  height: 100%;
}

.floating-action {
  position: fixed;

  @include transition(all 0.2s ease 0s);
  transform: scale(0);

  &.show {
    transform: scale(1);
  }

  button {
    border-radius: 50%;
    border: 0 none;
    padding: 0;
    margin: 0;
    line-height: 0px;
  }

  > button {
    @include transition(all 0.2s ease 0s);
    width: 56px;
    height: 56px;
    z-index: 1;

    i {
      @include transition(all 0.2s ease 0s);
    }

    .close-icon {
      transform: scale(0);
    }

    .action-icon {
      position: absolute;
    }
  }

  .sub-actions {
    position: absolute;
    bottom: 56px;
    z-index: 0;

    left: 50%;
    transform: translateX(-50%);

    button {
      $radius: 40px;
      $margin: 16px;

      width: $radius;
      height: $radius;
      margin-bottom: $margin;
      position: relative;
      transform: scale(0);

      @for $i from 1 through 10 {
        &:nth-last-child(#{$i}) {
          top: ($radius + $margin)*$i + 8px;
        }
      }
    }
  }

  &.expand {
    > button {
      .action-icon {
        transform: scale(0);
      }

      .close-icon {
        transform: scale(1);
      }
    }

    .sub-actions {
      button {
        background-color: $grey-100;
        color: $grey-900;
        @include transition(all 0.4s ease 0s);
        top: 0;
        transform: scale(1);
      }
    }
  }

  @for $i from 1 through 4 {
    &.lvl#{$i} {
      button {
        @include shadow($i);
      }

      &.expand button {
        @include shadow($i+1);
      }
    }
  }
}

.full-width {
  width: 100% !important;
}

// Таблицы
// https://www.google.com/design/spec/components/data-tables.html
.md-datatable {
  @include paper(1);

  > thead {
    color: rgba(#000, 0.54);
    font-size: 12px;
    font-weight: 500;
    > tr {
      border-bottom: 1px solid $separator-color;

      > th {
        height: 56px;
      }
    }
  }
  > tbody {
    color: rgba(#000, 0.87);
    font-size: 13px;
    font-weight: 400;
    tr {
      @include transition(background-color 0.2s linear 0s);
      height: 48px;

      &:hover{
        background-color: $grey-200;
      }
    }

    tr + tr {
      border-top: 1px solid $separator-color;
    }
  }

  td+td, th+th {
    padding-left: 56px;
  }

  td:first-child, th:first-child{
    padding-left: 24px;
  }

  td:last-child, th:last-child{
    padding-right: 24px;
  }
}

// Bootstrap fix
.bootstrap-datetimepicker-widget.top {
  &:before,&:after {
    display: none;
  }
}

// Bootstrap button with material icon
.btn-md {
  padding: 1px 5px;
  line-height: 1;
  border-radius: 3px;
}

#obj-group-btn {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis
}

//tree elements

.jquery-tree {
  .jquery-tree-node label i {
    margin-left: 0px;
  }
  label {
    display: inline;
    padding-left: 0px;
  }

  .jquery-tree-title {
    span {
      width: 14px;
      height: 14px;
      display: inline-block;
      vertical-align: sub;
      margin-right: 5px;

    }
  }

  .jquery-tree-unchecked {
    span {
      background:url(../img/icons/check.svg) no-repeat;
      background-size: cover;
    }
  }

  .jquery-tree-checked {
    span {
      background:url(../img/icons/check_blue.svg) no-repeat;
      background-size: cover;
    }
  }

  .transport-tree-icon {
    margin-left: 0;
  }

  label {
    font-weight: normal;
  }
}

.md-switcher {
  span {
    margin-right: 45px;
    border: none !important;
  }
}

.divider-page-selectmenu {
  border: none;
  padding: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.select-navobj {
  table {
    width: 100%;

    td {
      width: 100%;
      &:last-child{
        padding-left: 10px;
      }
    }
  }

  .input-navobj {
    width: 100%;
    cursor: pointer;
    input, label {
      cursor: inherit;
    }
  }

  button {
    white-space: nowrap;
  }
}

#navbar-options-dd {
  position: absolute;
  right: 0px;
  z-index: 1000;
  margin-right: 15px;
  display: block;
  height: auto;
  width: auto;
  top: 15px;

  >a {
    margin-bottom: 0px;
    margin-right: 0px;
    width: auto;
    height: auto;
    color: #ffffff;
    &:hover {
      color: #ffffff;
    }
  }

  #dd-navbar-options {
    width: auto;
  }
}

.resize-td-nav-head {
  cursor: w-resize;
  position: relative;
  width: 5px;
  height: 51px;
  top: 0;
  left: -1px;
}

#unload-track-dialog {
  table {
    tr {
      td {
        padding: 5px;
        img {
          cursor: pointer;
        }
      }
    }
  }
}

.content-guide {
  @include no-select;
  height: 0;
  position: relative;
  visibility: visible;
  width: calc(100% - 220px);
  bottom: 50%;
  margin: auto;
  text-align: center;
  label {
    display: block;
    font-weight: normal;
    margin-bottom: 0;
  }
  i, label {
    color: #808080;
  }
  .material-icons {
    cursor: default;
    font-size: 80px;
  }
}

.content-guide-container {
  width: 300px;
}

#chart-slctobj-guide {
  font-weight: normal;
  display: block;
  width: calc(100% - 200px);
  margin: 20px auto 0;
  color: #808080;
}

#chart-helper {
  width: calc(100% - 70%);
  top: 40%;
  bottom: 0px;
}

.btn-md-icon {
  padding: 0px 5px;
  height: 30px;
  width: 34px;
  i {
    font-size: 22px;
    vertical-align: middle;
  }
}

.ui-spinner {
  display: block;
}

#tab-admin-general {
  height: 300px;
  overflow-y: auto;
}

#tab-admin-map-popup {

  height: 300px;
  overflow-y: auto;

  .mobj-attrs {
    margin-top: 10px;
    padding-top: 10px;
    border-top: 2px solid #337AB7;

    table {
      width: 100%;
      margin-top: 10px;

      th, td {
        padding: 3px;
        border: solid 1px;
      }

      td {
        input {
          width: 100%;
        }

        &:last-child {
          text-align: center;

          span {
            cursor: pointer;

          }
        }
      }
    }

  }
}


.scrolled-tabs {

  //padding: 0 24px;
  position: relative;


  .hidden-tabs {
    margin: 0 24px;
    overflow: hidden;
  }

  .overflow-tabs {
    position: relative;
    float: left;
    //margin: 0 24px;

    >ul {
      width: 100%;
      display: table;

      li {
        display: table-cell;
        float: unset !important;

        a {
          display: block;
        }
      }
    }
  }

  .prev-tab,
  .next-tab {
    font-weight: 600;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .prev-tab {
    left: 0;
    display: none;
  }

  .next-tab {
    right: 0;
  }
}

.dropdown-mstv {

  position: relative;

  .dropdown-toggle {
    position: relative;
    padding-right: 15px;

    &:after {
      position: absolute;
      content: "";
      width: 0;
      height: 0;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 5px solid #000;
      right: 6px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .multiselect-treeview {

    height: auto;
    max-height: 350px;
    overflow-x: hidden;

    .li-unchecked {
      padding: 0 5px;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      position: relative;

      li {
        position: relative;

        >a {
          width: 100%;
          display: block;
          line-height: 25px;
          padding: 5px;
          color: #000;

          >label {
            width: 100%;
            margin: 0;
            font-weight: normal;

            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;

            input[type="checkbox"] {
              width: 16px;
              height: 16px;
              margin: 0;
              top: 3px;
              position: relative;
              margin-right: 4px;
            }

          }

          &:hover {
            background-color: #dadada;
          }
        }
      }
    }

    .base-ul {

      ul {
        &:before {
          content: "";
          display: block;
          width: 0;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          border-left: 1px solid;
        }

        li {
          &:before {
            content: "";
            display: block;
            width: 10px;
            height: 0;
            border-top: 1px solid;
            position: absolute;
            left: -12px;
            top: 1em;
            margin-top: 1px;
          }

          &:last-child:before {
            background: #fff;
            height: auto;
            bottom: 0;
          }
        }
      }
    }

    .branch {

      >i {
        width: 20px;
        height: 20px;
        display: block;
        position: absolute;
        top: 7px;
        left: 2px;
        cursor: pointer;

        background-position: center;
        background-size: 18px;
        background-repeat: no-repeat;
        background-image: url(../img/multiselect-treeview/folder.svg);
      }

      >a {
        padding-left: 25px;
      }

      >ul {
        display: none;
        margin-left: 8px;
        padding-left: 12px;
      }

      &.expand {

        >i {
          background-image: url(../img/multiselect-treeview/folder-open.svg);
        }

        >ul {
          display: block;
        }
      }
    }

    .divider {
      border-bottom: 1px solid #dadada;
      margin: 0;
    }
  }
}

#obj-group-context-menu {
  ul {
    overflow: hidden;
    li a i {
      width: 24px;
    }
  }
}

.dropdown-toggle {
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}