@import "base", "colors";

.authorize {
	body {
		height: 100%;
		width: 100%;
		background: url('/img/authBgr.jpg') no-repeat fixed bottom;
	}

	button,input {
		font: inherit;

	}

	a {
		text-decoration: none;
	}

	.ui-dialog-titlebar-close {
		display: none;
	}

	.auth-wrapper {
		position: relative;
		top: 50%;
		@include transform(translateY(-50%));

		text-align: center;
		font-size: 14pt;

        >img {
		  margin-bottom: 45px;
          max-width: 400px;
          max-height: 250px;
        }
	}

	#tagline {
		font-size: 16pt;
		padding: 16px 0px 40px;
		margin: 0;
	}

	.auth-form {
		width: 500px;
		margin: 0 auto;

		button {
			border-radius: 10px;
			height: 40px;
			background-color: transparent;
			border: 1px solid black;
			padding: 0;

			&:hover {
				color: #fefefe;
				background-color: #16509d;
				border-color: #16509d;
			}
		}

	}

	.auth-input {
		border-radius: 10px;
		border: 1px solid #000000;
		margin-bottom: 20px;
		padding: 0 10px;

		&:focus-within,
		&:hover {
			border-color: $blue-600;
		}

		input {
			height: 38px;
			width: 100%;
			margin: 0;
			padding: 0;
			border: medium none;
			background-color: transparent;
		}
	}

	.auth-btns {
		margin-bottom: 20px;

		display: flex;
		flex-direction: row;
		justify-content: space-between;

		> button {
			flex: 1 1 auto;
			&:focus,
			&:hover {
				border-color: $blue-600;
			}
		}

		> i {
			flex: 0 0 auto;
			width: 20px;
		}
	}

	#auth-login-cloud {
		a {
			margin: 0 2px;
		}
	}

	.lang-select {
		position: relative;

		
	}

	// #lang-select {
	// 	display: block !important;
	// 	position: absolute !important;
	// 	height: 1px; 
	// 	width: 1px;
	// 	overflow: hidden;
	// 	clip: rect(1px 1px 1px 1px); 
	// 	clip: rect(1px, 1px, 1px, 1px);
	// 	white-space: nowrap; 

	// 	&:active+.lang-select,
	// 	&:focus+.lang-select {		
	// 		.lang-select-button {
	// 			border-color: $blue-600;
	// 		}
	// 	}
	// }

	.lang-select-button {
		border-radius: 10px;
		border: 1px solid #000000;
		cursor: pointer;
		line-height: 35px;
		display: block;

		i {
			vertical-align: middle;
			color: $grey-500;
		}
	}

	.lang-select-img {
		width: 35px;
		height: 35px;
		display: inline-block;
		vertical-align: middle;
		margin: 0px 8px 0 16px;

		&.ru_RU {
			background: url('/img/icons/ru.png') no-repeat center;
		}

		&.en_US {
			background: url('/img/icons/en.png') no-repeat center;
		}
	}

	.lang-dropdown {
		list-style-type: none;
		position: absolute;
		border-radius: 10px;
		border: 1px solid #000000;
		background-color: #ffffff;
		top: -8px;
		width: 100%;
		text-align: left;
		padding: 10px 0;
		display: none;

		&.expand {
			display: block;
		}

		li {
			cursor: pointer;

			&:hover {
				background-color: $darkblue-200;
			}
		}
	}
}