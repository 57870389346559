@import "colors";

html {
	height: 100%;
}
html,body,button,input {
	font-family: "Roboto", sans-serif;
	font-size: 13px;
	color: $grey-900;
}
* {
	box-sizing: border-box;
}
body {
	margin:0;
	line-height: 1.42857;
}
img {
	vertical-align: middle;
	border: 0 none;
    -ms-interpolation-mode: bicubic;
}
th {
	font-weight: 500;
}
ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
}
input[type="text"] {
	outline: none;
	border: none;
}
a:focus, button:focus {
	outline: none;
}
button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner {
	border: none;
}

.right {
	float: right !important;
}

.left {
	float: left !important;
}

.right-float {
	float: right !important;
	margin-right: 3px;
}

.left-float {
	float: left !important;
	margin-left: 3px;
}

.fit-width {
	width: 100%;
}

@for $i from 1 through 39 {
	.col#{$i}-hid {
		> tbody > tr > td:nth-child(#{$i}),
		> thead > tr > th:nth-child(#{$i}){
			display: none;
		}
	}

	.col#{$i}-right {
		> tbody > tr > td:nth-child(#{$i}),
		> thead > tr > th:nth-child(#{$i}){
			text-align: right;
		}
	}

	.col#{$i}-center {
		> tbody > tr > td:nth-child(#{$i}),
		> thead > tr > th:nth-child(#{$i}){
			text-align: center;
		}
	}
}