@import "variables";

.nis-controls {
  display: table;
  width: 100%;
  padding-top: 5px;

  .period-selector {
    width: auto;
  }
}

#nis-dashboard-container {
  width: 100%;
  height: 100%;

  .nis-table {
    width: 100%;
    border-collapse: collapse;

    thead {
      background-color: get-color(3);
      text-align: center;
      color: get-color(1);
      font-size: 14px;
      min-height: 45px;
    }

    tr td {
      padding: 2px 5px;
      border: 1px solid get-color(2);
    }

    tbody {
      background-color: get-color(4);

      .value {
        text-align: center;
        font-weight: 900;
      }
    }
  }

  .nis-chart-container {
    .nis-chart-head {
      background-color: get-color(3);
      text-align: center;
      color: get-color(1);
      font-size: 14px;
      //min-height: 45px;
      height: 40px;
      border: 1px solid get-color(2);
      padding: 0 90px;
    }

    .nis-chart-content {
      border-bottom: 1px solid get-color(2);
      border-right: 1px solid get-color(2);
      border-left: 1px solid get-color(2);
      background-color: get-color(4);

      .nis-chart-overflow {
        max-height: 545px;
        overflow-x: hidden;
        overflow-y: auto;
      }

      .nis-chart-info {
        padding: 0 10px;


        >* {
          margin-bottom: 10px;
        }


        .nis-chart-sum {

          .nis-chart-sum-value {
            font-weight: 600;
            font-size: 14px;
          }
        }
      }
    }

    .pie-chart {
      height: 180px;

      .highcharts-legend {
        transform: translate(255px, 10px) !important;
        height: 100% !important;
      }

      .highcharts-series-group,
      .highcharts-title,
      .highcharts-data-labels {
        transform: translate(-50px, 0) !important;
      }

    }

    .column-chart {
      height: 385px;
    }
  }

  .nis-content {
    height: 100%;
    overflow-y: auto;

    >.row {

      >[class^="col-"] {

        >* {
          margin-bottom: 10px;
        }
      }
    }
  }
}

#nis-journal-container {
  width: 100%;
  height: 100%;

  .nis-journal-table-overflow-x {
    width: 100%;
    height: 100%;
    overflow-x: auto;
    overflow-y: hidden;
  }

  .nis-journal-table-normal {
    height: 100%;
    width: 100%;
  }

  .nis-journal-table-wrapper {
    height: 100%;
    min-width: 2500px;
  }

  .nis-journal-table-overflow-y {
    width: auto;
    //width: 100%;
    display: inline-block;
    height: calc(100% - 80px);
    -ms-overflow-style: auto;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .nis-journal-table-header {
    border-collapse: inherit;
    height: 52px;

    .td-group {
      width: auto;
    }

    td:not(.td-group) {
      /*
      &:not(:first-child):not(:last-child){

        &:not(:nth-child(2)) {
          min-width: 80px;
        }
      }
      */

      line-height: normal;
      text-align: center;
      white-space: normal;
    }

    /*
    .violation {
      background-color: get-color(5);
      border-left: 1px solid get-color(5);

    }
    */
  }

  .nis-journal-table {
    border-collapse: inherit;

    tr {

      td {

        border-bottom: 1px solid grey;

        &:not(:first-child) {
          padding: 3px;
          border-left: 1px solid grey;
          background-clip: padding-box;
          min-width: 70px;
          //max-width: unset;
          //text-align: center;
          max-width: 250px;
          white-space: normal;
        }

        &.number {
          min-width: 40px;
        }

        &.time {
          min-width: 60px;
        }

        &:last-child {
          border-right: 1px solid grey;
        }

        &.check-location {
          max-width: 30px;
          min-width: 30px;

          >i {
            cursor: pointer;
          }

        }
      }
    }

  }

  /*

  #tab-nis-journal-dtps {


    #nis-journal-dtps-header {
      height: 96px;
    }

    .nis-journal-table-overflow-y {
      height: calc(100% - 96px);
    }

  }
  */
}

#print-nis-dashboard,
#export-nis-journal {
  line-height: 1;
  padding: 3px 9px;
  margin-top: 2px;
}

#tabs-nis-dashboard,
#tabs-nis-journal {
  height: calc(100% - 41px);
  overflow: hidden;

  >div {
    height: calc(100% - 39.8px);
  }
}


#nis-roadsigns-container {
  height: calc(100% - 36px);


  #nis-roadsigns-table-overflow {
    height: calc(100% - 21px);
    overflow-y: auto;
  }
}