@import "colors", "base";

#mi-head {
	text-align: center;
	height: 60px;
	@include shadow-bottom;
	position: relative;
}

#mi-username {
	font-size: 18pt;
	text-align: center;
	width: 100%;
}

#mi-cards {
	height: calc(100% - 60px);
}

#maininfo-container {
	background-color: $grey-100;
}

#maininfo-controls {
	background-color: white;
	padding: 8px;
	height: 61px;
	position: relative;
	@include shadow-bottom(1);
}

#maininfo-content {
	overflow: auto;
	height: calc(100% - 61px);
	text-align: center;
}

#maininfo-tables {
	border: 32px solid transparent;
	display: inline-block;
	text-align: left;
}

.maininfo-table {
	padding-left: 10px;
	overflow: auto;

	> tbody > tr {
		height: 25px;

		> td {
			padding-left: 5px;
		}
	}
}