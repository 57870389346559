.tfm-container {
  height: 100%;
  background: #f2f1f0;
  padding: 10px 0px 0px 0px;
  overflow-y: scroll;
  font-family: Roboto, serif;
  font-size: 16px;
  position: relative;

  .btn-outline {
    background-color: #fff;
    color: inherit;
    transition: all .5s;
    width: 120px;
  }

  .tfm-controls {
    position: relative;

    .tfm-current-date {
      margin-right: 10px;
    }

    .tfm-shift-changer {
      .btn {
        min-width: 130px;
      }

      .active {
        color: #fff;
        background-color: #337ab7;

        &:focus {
          outline: none;
        }
      }
    }

    .tfm-date-picker {
      margin-left: 10px;
      display: none;

      input {
        background: #fff;
        border: 1px solid #ccc;
        border-radius: 5px;
        padding: 15px;
        margin-top: 3px;
        height: 34px;
      }
    }

    .tfm-reload-dashboard {
      &:hover, &:focus {
        color: #fff;
      }
    }

    .tfm-plan-percent-container {
      margin-top: -12px;
      float: right;
      width: 50%;
      text-align: center;

      label {
        font-size: 12px;
      }

      .tfm-plan-percent {
        margin: 0;
      }
    }
  }

  .tfm-main {
    margin-top: 11px;

    .isloading-overlay {
      background: #fff;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0px;
      left: 0px;
      opacity: 1;
      display: none;

      img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: 13%;
        width: 33%;
        max-width: 200px;
      }
    }
  }
}

#tfm-page-dashboard {
  #tfm-areas {
    padding-right: 15px;
    padding-left: 15px;

    .col-md-3 {
      padding-right: 0px;
      padding-left: 0px;
    }

    .tfm-area {
      margin-bottom: 15px;
      text-align: center;
      background: #fff;
      border-right: 4px dotted #f2f1f0;

      .tfm-area-header {
        color: #fff;
        font-size: 18px;
        min-height: 55px;
        padding: 15px;
        margin-left: 35px;
        margin-right: 35px;
        word-wrap: break-word;
        cursor: pointer;

        label {
          cursor: pointer;
        }
      }

      .tfm-area-body {
        min-height: 320px;
        padding: 15px;
        position: relative;

        .tfm-area-chart-container {
          min-height: 100px;
        }

        .tfm-area-table-container {
          .tfm-area-table {
            width: 100%;
            font-size: 14px;

            .noborder {
              border: 1px solid #afafaf;
              border-right: 0;
              border-bottom: 0;
            }

            th {
              text-align: center;
              font-weight: 300;
            }

            th, td {
              border: 1px solid black;
            }
          }
        }

      }
    }
  }
}

#tfm-page-area {
  .tfm-tabs {
    margin-bottom: 25px;

    #tfm-tabs-control {
      li {
        //width: 25%;
        width: 33.3%;

        &.active > a {
          background: #357fc2;
          color: white;
          font-size: 16px;
        }
      }
    }
  }

  .tfm-main {
    .tfm-dumptrucks-table-container {
      margin-top: 15px;
    }
    .tfm-cart {
      margin-bottom: 15px;
    }
    .tfm-accordion-parent{
      cursor: pointer;
    }
    .tfm-accordion-child {
      display: none;
    }
    .tfm-accordion-child-expanded {
      display: table-row;
    }
  }
}
