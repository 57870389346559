#div-search-obj-filter {
  display: none;
}
html, body, button, input {
  font-size: 12px;
}

//юи
.message-box {
  .ui-dialog-titlebar {
    font-size: 18px;
    padding: 12px 12px 10px 12px;
  }
  .ui-dialog-content {
    padding: 0 12px 12px 12px;
    font-size: 12px;
  }
  .ui-button {
    font-size: 12px;
  }

}

.ui-dialog {
  width: 100% !important;
}

.md-form-content {
  > .input-field,
  > .action-field,
  > .input-field-row {
    margin: 7px 0;
  }
}

.mb-flat {
  min-width: 48px;
  font-size: 12px;
  height: 28px;
}

.dropdown-menu {
  font-size: 12px;
  max-height: 300px;
  li {
    a {
      line-height: 30px;
      font-size: 12px;
      padding-left: 30px;
      min-width: 180px;
      i {
        left: 3px;
      }
    }
  }
}

.leaflet-control-layers {
  max-height: 200px;
}

/*
.material-icons {
  font-size: 22px;
  top: 0;
  line-height: 30px;
}
*/

.content-guide-container {
  display: none !important;
}
.content-guide {
  display: none !important;
}

// авторизация
.authorize {
  .auth-wrapper {
    width: 100%;
    height: 100%;
    padding: 20px;
    overflow: auto;
  }

  .auth-form {
    width: 100%;
  }
}

#register-form {
  width: 500px;
}

header { display: none; }
#left-panel, #side-menu, { top: 0; }

#page-head-mobile {
  color: #fff;
  background-color: #337AB7;
  width: 100%;
  height: 50px;
}

#page-head-mobile, main {
  left: 0 !important;
  visibility: hidden;
  .hd-top-line {
    line-height: 50px;
    height: 50px;
    font-size: 13pt;
  }
}

#hd-action {
  margin-right: 15px;
  display: none;
}

#page-head-mobile {
  i {
    padding: 13px 10px;
  }
}

.head-mobile-divider {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}

#head-mobile-item {
  height: 50px;
  line-height: 50px;
  display: inline-flex;
  width: auto;
  cursor: pointer;
  i {
    padding: 13px 0 13px 10px;
  }
  label {
    margin: 0;
    font-weight: normal;
    line-height: 50px;
    font-size: 13pt;
  }
}

#mobject-tooltip {
  width: 100%;
}

#mobject-tooltip #mobject-tooltip-body > table {
  width: 100%;
  margin: 0;
}

#left-panel {
  width: 100% !important;
  //display: none;
}

.page-content {
  overflow-x: hidden;
}

.nav-table-header, #obj-table {
  tr > td:not(:first-child) {
    min-width: 14px !important;
  }
}

.nav-table {
  > tbody {
    tr {
      td {
        &:nth-child(2) {
          padding-left: 2px;
        }
        &:not(:first-child) {
          max-width: 150px;
        }
        &:nth-child(4) {
          max-width: 140px;
        }
      }
    }
  }

  font-size: 10px;
  img {
    width: 16px;
    height: 16px;
  }
  span {
    width: 12px !important;
    height: 12px !important;
    background-size: cover;
  }
}
.nav-table-header {
  svg {
    width: 12px !important;
    height: 12px !important;
  }
}

#side-menu {
  left: -70%;
  width: 70%;
  #side-head {
    height: 50px;
    .logo-company {
      height: 50px;
      margin: 0;
      padding: 0;

      .company-name {
        display: none;
      }

      img {
        margin-left: 16px;
        margin-top: 8px;
        width: 100px;
      }
    }
    p:last-child { display: none; }
  }
}
#side-menu-items {
  height: calc(100% - 50px);
}

.fixed-scrollable-menu {
  max-width: 250px;
  font-size: 12px;
  li {
    width: auto;
    i:not(.material-icons) {
      font-size: 14px;
    }
    a {
      white-space: nowrap;
    }
  }
}

//отчеты
.one-scheme {
  table {
    line-height: normal;
  }
}

//track
#track-player {
  min-width: 100%;
  margin: 0;
  left: 0;
  .track-date {
    font-size: 8pt;
  }
}

.tracking-control {
  width: 200px;
  top: 0;
  line-height: 30px;
}

// zones
#zone-editor {
  top: 10px;
  width: 200px;
  z-index: 1001;
}

.sub-container {
  width: 100% !important;
  visibility: hidden;
}


//ridership
#routerounds-container {
  width: 100% !important;
}

#routerounds {
  min-width: 100% !important;
}
.leaflet-routerounds {
  position: absolute !important;
}

#routerounds-context-menu {
  ul {

    max-height: 200px !important;
    overflow-y: auto !important;
  }
}

#routeBreaches {
  right: 0;
  left: 0;
  width: 100%;
  #routeBreaches-header {
    >b {
      display: none;
    }
  }

  #routeBreaches-footer {
    padding: 0px 10px;
    span {
      margin: 0;
    }
  }
}

#routerounds-settings {
  height: auto !important;
  display: inline-block;
  >.left {
    width: 60%;
    >div {
      width: 100%;
    }
  }

  #show-breaches {
    padding: 5px;
    margin-top: 6px;
  }
}

.ws-container-overflow-x {
  width: auto !important;
  display: inline-block;
}


/* reports */
#report-controls, #report-full-content {
  padding: 0px 5px 10px 5px;
}

#report-controls {
  #report-only-totals,
  #report-hide-empty,
  #report-columns-btn {
    margin-left: 2px;
  }
}

#report-full-content {
  overflow-y: auto;
  #report-content-left {
    border: 1px solid #9E9E9E;;
    margin-bottom: 10px;
    width: 100% !important;
    height: auto !important;
    border-radius: 2px;
    .list-item-action {
      height: 38px;
    }

    .div-additional-table {
      height: 150px;
    }
  }
  #report-content {
    width: 100% !important;
  }
}

#mobile-report-build {
  display: block;
}