#report-selector {
  margin: 7px;
}

#ul-reports{
  .branch {
    padding: 5px;
  }

  li:not(.branch) {
    span {
      display: block;
      padding: 3px 20px 3px 10px;
    }
  }
}

#report-container {
    height: 100%;
    background-color: #ffffff;
}

#report-content,
#report-content-left {
	height: 100%;
}

#report-content-left {
  width:20%; height: 100%;
}


#report-controls {
    width: 100%;
  #report-only-totals,
  #report-hide-empty,
  #report-columns-btn {
    margin-left: 15px;
  }
}

#report-params {
  margin-bottom: 15px;
  >div {
    max-height: 300px;
    overflow-y: auto;
  }
}

#report-controls,
#report-full-content {
  padding: 0px 10px 10px 10px;
}

#report-additional {
  >div {
    &:first-child {
      padding-bottom: 10px;
    }

    .input-group {
      width: 100%;
      margin-bottom: 5px
    }
    .div-additional-table {
      overflow-y: auto;
      height: calc(100% - 31px);
      border: 1px solid #e0e0e0;
    }
  }
  button {
    height: 26px;
    padding: 0px 5px;
  }
  .rem-addit-obj {
    margin-right: 5px;
    width: 30px;
    span {
      vertical-align: middle;
    }
  }
}

#report-full-content {
  height: calc(100% - 55px);
}


#table-scheme-controls label {
    margin: 0px;
    padding: 0px;
}

#table-scheme-controls table {
    height: 100%;
}

#table-scheme-controls table td {
	&:nth-child(2),
	&:nth-child(3),
	&:nth-child(4)
	{
		border-right: 1px solid #ffffff;
	}
}

#table-scheme-controls table td:nth-child(5) {
    padding-left: 5px;
}

#selected-report {
    padding: 1px 1px 1px 8px;
}

#report-controls > div {
    height: 100%;
}

#report-groupby {
  width: 130px;
}

#report-tabs {
    height: 100%;
    border: none;
}

#report-content table {
    margin-bottom: 100px;
}

/* http://www.colorzilla.com/gradient-editor/ */
#report-content thead {
    line-height: normal;
    background: #f6f8f9;
    background: -moz-linear-gradient(top, #f6f8f9 0%, #e5ebee 50%, #d7dee3 51%, #f5f7f9 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#f6f8f9), color-stop(50%,#e5ebee), color-stop(51%,#d7dee3), color-stop(100%,#f5f7f9));
    background: -webkit-linear-gradient(top, #f6f8f9 0%,#e5ebee 50%,#d7dee3 51%,#f5f7f9 100%);
    background: -o-linear-gradient(top, #f6f8f9 0%,#e5ebee 50%,#d7dee3 51%,#f5f7f9 100%);
    background: -ms-linear-gradient(top, #f6f8f9 0%,#e5ebee 50%,#d7dee3 51%,#f5f7f9 100%);
    background: linear-gradient(to bottom, #f6f8f9 0%,#e5ebee 50%,#d7dee3 51%,#f5f7f9 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f6f8f9', endColorstr='#f5f7f9',GradientType=0 );
}

#report-content th {
    font-weight: normal;
    border-right: 1px solid #B0B0B0;
    border-left: 1px solid #B0B0B0;
    border-bottom: 2px solid #B0B0B0;
    padding-left: 2px;
    padding-right: 2px;
    text-align: center;
}

#report-content td {
    border: 1px solid #B0B0B0;
    padding-left: 2px;
    padding-right: 2px;
}

.scheme-container {
    height: 100%;
    overflow: auto;
}

.one-scheme {
    height: 100%;
}

.report-content-right {
  width: 79% !important;
}

.rr0 {
    border-top: 2px solid #B0B0B0;
    background-color: #d3eaff;
}

.rr1 {
    background-color: #ffe2c5;
}

.rr2 {
    background-color: #ffcfd9;
}

.rr6, .rr7, .rr8, .rr9, .rr11 {
    background-color: #ddf6c6;
}

#report-export {
    position: fixed;
    right: 22px;
    bottom: 22px;
    border-radius: 5px;
    background-color: #ffffff;
    padding: 6px 3px 6px 3px;
    display: none;
	> a {
		margin: 4px;
	}
}

.report-format-select {
	text-align: center;
	a {
		margin: 16px;
	}
}

.cell-span { display: table-cell !important; }

#report-parameters {
   // position: absolute;
   // top: 62px;
    width: 100%;
}

#report-parameters td {
    padding: 1px 8px 4px;
}

#report-parameters input,
#report-parameters select {
    color: #000000;
}

.rep-attr-time {
    width: 68px;
    text-align: center;
}

#report-obj-container {
  height: calc(100% - 96px);
}

.rep-page {
  padding: 12px 12px 0 12px;
}

.tab-rep-additional-content {
  overflow-y: auto;
  height: 300px;
  padding: 0 !important;
}

#ul-rep-additional-zonestree {
  height: 100% !important;
}
#mobile-report-build {
  display: none;
}

#report-user-container {
  height: calc(100% - 96px);
  overflow-y: auto;
}