@import "base", "colors";
// TODO Разбить по составляющим

#main-splitter {
	opacity: 0;
	cursor: col-resize;
	width: 4px;
	height: 100%;
	left: 0;
	position: absolute;
	z-index: 1001;
	display: block;

	&.moved {
		background-color: #000000;
		opacity: 0.3;
		position: fixed;
	}
}


#small-splitter {
  position: absolute;
  height: 30px;
  display: block;
  width: 50px;
  background-color:
  black;
  z-index:
  1001;
  top: 50%;
  left: 0;
  &.moved {
    background-color: #FF0000;
    opacity: 0.3;
    position: fixed;
  }
}

$periodheight: 36px;

/* Additionals */

.fixed-scrollable-menu {
	height: auto;
	max-height: 350px;
	overflow-x: hidden;
}

.loading-bgr {
	background: transparent url("/img/loading.gif") no-repeat scroll 50% 50%;
}

/* Всплывающее окно автомобиля */

.nav-popup {
	color: $grey-900;
	border: none;
	padding: 5px;
	text-align: left;

	th {
		text-align: center;
		font-weight: 500;
	}

	th, td {
		padding: 2px;
	}

	img {
		padding-right: 3px;
		padding-bottom: 1px;
	}
}

/* Всплывающее окно трека */
#ti-popup {
  > table {
    tr {
      border-bottom: 1px solid #c9caca;

      &:nth-last-child(1) {
        border-bottom: none;
      }
      td {
        padding: 4px;
      }
    }
  }

  .td-right-border {
    border-right: 1px solid #c9caca;
  }

  .infotable-title {
    padding-right: 10px;
    color: #8b8b8a;
  }
}

/* Tables */
.nav-table {
	font-size: 11px;
	width: 100%;
	@include no-select;
	cursor: default;

	> thead > tr {
		height: 25px;
		@include shadow-bottom;

		> td:first-child { // Из-за бордера-индикатора делаем паддинг у первого столбца
			padding-left: 6px;
		}
	}

	> tbody > tr {
		height: 25px;
		//border-left: 6px solid transparent;

		&:hover,
		&.focused:hover {
			background-color: $hover-color;
		}

		> td {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;

			> input[type="checkbox"] {
				margin: 0;
				vertical-align: middle;
			}

			&:first-child {
				width: 5px;
				min-width: 5px;
				max-width: 5px;
			}

			&:not(:first-child) {
				min-width: 16px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 180px;
			}
            &:nth-child(4) {
              max-width: 150px;
            }

			&:nth-child(2) {
				width: 239px;
				padding-left: 4px;
			}

            > span {
              display: block;
              width: 16px;
              height: 16px;
              margin-right: 3px;
            }
		}

		&.focused {
			background-color: $hover-color;
		}

		&.focused:hover {
			background-color: $hover-color;
		}

		&.focused > td:first-child {
			background-color: $darkblue-300;
		}

		&.virtual {
			&:hover,
			&.focused:hover {
				background-color: transparent;

				> td:first-child {
					background-color: transparent;
				}
			}
		}
	}

	&.selectable > tbody > tr {
		border-left: 0 solid transparent;
	}

	.material-icons {
		display: block;
	}
}

.nav-table-header {
	width: 100%;
	height: 20px;
	table-layout: fixed;
	font-size: 9pt;
	transform: translate3d(0px, 0px, 0px);
	z-index: 1;
	@include no-select;
	@include shadow-bottom;

	> tbody > tr > td {
		padding: 0 2px;

		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;

		&:first-child {
			width: 5px;
			min-width: 5px;
			max-width: 5px;
		}

		&:not(:first-child) {
			min-width: 16px;
			border-left: 1px solid #eee;
		}

		&.td-sort {
			cursor: pointer;

			span {
				position: relative;
				width: 100%;
				display: block;

				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			&.select-td-sort {
				span {
					padding-right: 12px;


					i {
						position: absolute;
						right: 0;
						top: 0;
						height: 12px;
						width: 12px;
					}
				}
			}
		}

		path {
			fill: $grey-600;
		}
	}
}

.no-value-hidden {
	.no-value {
		display: none;
	}
}

/*.master-alert .right-float{
	display: none;
}*/

/* tutorial slide */
.slide-dialog .ui-dialog-buttonset {
	width: 100%;
	.ui-button {
		float: right;
	}
}
.slide{
	width:965px;
	height:335px;
	display:inline;
	float:left;
	position: absolute;
}
#btn-prev {
	float: left;
	margin-left: 8px;
}

/* Chart */
.chart-helpers-text {
	font-size: 16pt;
}

.chart-helpers-text2 {
	font-size: 17pt;
	text-align: center;
	position: absolute;
	top: 202px;
	left: 138px;
}

#chartpanel {
	height: 100%;
	position: relative;
	overflow: hidden;
}

.ui-icon.ui-chart-line-dots-type-solid {
	background: url('../img/icons/solid.png') 0 0 no-repeat;
}
.ui-icon.ui-chart-line-dots-type-longdash {
	background: url('../img/icons/longdash.png') 0 0 no-repeat;
}
.ui-icon.ui-chart-line-dots-type-shortdot {
	background: url('../img/icons/shortdot.png') 0 0 no-repeat;
}

#series-settings td {
	padding-right: 15px;
	padding-top: 10px;
}

/* Objects */
#obj-container {
	height: 100%;
}

#objects-controls {
	padding: 6px 0;
	width: 100%;
}

#obj-header {
	cursor: pointer;

	td {
		&:nth-child(3),
		&:nth-child(6),
		&:nth-child(7) {
			text-align: center;
		}

		&:nth-child(5) {
			text-align: right;
		}
	}
}

.header_icon {
	opacity: 0.54;
	font-size: 20px;
	margin-top: -5px;
	position: relative;
	top: 3px;
}

#obj-table-container{
    width: 100%;
    overflow-x: hidden;
    height: calc(100% - 63px);
}

#obj-group-div {
	width: auto;
}

#eye-header {
	display: none;
    padding: 0px 5px;
    height: 30px;
    width: 34px;
    i {
      font-size: 22px;
      vertical-align: middle;
    }
}

#btn-obj-subgroups {
  width: 34px;
}

.monitoring #eye-header {
	display: inherit;
}

#obj-table{
	cursor: context-menu;

	> tbody > tr {
		> td {
			&:not(:first-child) {
				//min-width: 16px;
			}

			&:nth-child(5) {
				text-align: right;
				padding-right: 4px;
				//min-width: 40px;
			}

			&:nth-child(3),
			&:nth-child(6),
			&:nth-child(7){
				width: 18px;
			}

			> input[type="checkbox"] {
				display: none;
			}
		}
	}
}

.monitoring #obj-table > tbody > tr > td > input[type="checkbox"] {
	display: inherit;
}

.no-nav {
	color: #B0B0B0;
}
.repair {
  color: $red-500;
}
.reserve {
  color: $indigo-500;
}

.s0 {
	background-image: url('../img/icons/s0.png');
}

.s1 {
	background-image: url('../img/icons/s1.png');
}

.m0 {
	background-image: url('../img/icons/m0.png');
}

.m1 {
	background-image: url('../img/icons/m1.png');
}

.m2 {
	background-image: url('../img/icons/m2.png');
}

.m3 {
	background-image: url('../img/icons/m3.png');
}

.sg0 {
	background-image: url('../img/icons/sg0.png');
}

.sg1 {
	background-image: url('../img/icons/sg1.png');
}

.sg2 {
	background-image: url('../img/icons/sg2.png');
}

.sg3 {
	background-image: url('../img/icons/sg3.png');
}

.sg4 {
	background-image: url('../img/icons/sg4.png');
}

.sg5 {
	background-image: url('../img/icons/sg5.png');
}

.key {
	background-image: url('../img/icons/key.png');
}

.state-icon {
	display: block;
	width: 16px;
	height: 16px;
}

/* Object Label */

.obj-label {
	padding: 4px 8px;
	td:not(:first-child):not(:empty) {
		padding-left: 6px;
	}
}

/* Object-Tooltip */
#mobject-tooltip {
	position: fixed;
	z-index: 11000;

	display: none;

	width: 490px;
	min-height: 170px;
	padding: 15px;

	text-align: left;

	@include paper(2);

	#mobject-tooltip-header {
		font-size: 14px;
		font-weight: normal;
		line-height: 16px;
	}

	#mobject-tooltip-body {
		padding-top: 10px;

		> table {
			width: 454px;
			margin: 0 auto;

			tr {
				border-bottom: 1px solid #c9caca;

				&:nth-last-child(1) {
					border-bottom: none;
				}

				td {
					padding: 2px;
				}
			}
		}

		.table-right-border {
			border-right: 1px solid #c9caca;
		}

		.table-no-bottom-border {
			border-bottom: 1px solid #ffffff !important;
		}

		.infotable-title {
			width: 120px;
			color: #8b8b8a;
		}

		.pad {
			padding-left: 4px !important;
		}
	}
}

.obj-attr-group {
  padding: 0px 25px 20px 25px;
  border: 1px solid silver;
  margin-bottom: 15px;
  legend {
    font-size: 15px;
    width: auto;
    border: none;
    margin: 0px;
  }
}

/* Tracks */

$trackplayerheight: 70px;

.tracks-tab {
	padding-bottom: $periodheight;
}

.track-custom {
	display: none;
}

.selectable .track-custom {
	display: block;
	width: 22px;
	height: 6px;
	border-radius: 3px;
	position: relative;
	right: 9px;
  top: 10px;
  float: right;
}

#track-obj-container {
	height: 100%;
}

#track-player {
    position: absolute;
    background-color: white;
    min-width: 450px;
    width: auto;
    bottom: 0;
    left: 50%;
    margin-left: -215px;
	height: $trackplayerheight;
	padding: 5px 16px;
	border-top: 1px solid $separator-color;
    .bt-track-pause {
      display: none;
    }
}
.track-date {
	float: right;
}

#track-settings-table td {
	padding: 3px 5px;
}

#track-width {
	width: 21px
}

#tracks-clear {
	display: none;
}

.tracks #tracks-clear {
	display: inherit;
}

/* Message-History */
$msgtoolsheight: 25px;
$msgheaderheight: 20px;

.messages-tab {
	padding-bottom: $periodheight + $msgtoolsheight + $msgheaderheight;
	&.expand {
		padding-bottom: $periodheight;
	}
}

#messages-object {
	height: 100%;
}

#messages-tools {
	padding-left: 8px;
	height: $msgtoolsheight;
}

#mess-obj-selector {
	height: 21px;
	width: 250px;
	float:left;
	padding-left: 3px;
	cursor: pointer;
}

#messages-container {
	font-size: 9pt;
	height: 100%;
}

#messages-table-container {
	height: 100%;
	overflow: auto;
}

#messages-header {
	height: $msgheaderheight;
	width: 100%;
	table-layout: fixed;
	box-shadow: 0 5px 7px -5px rgba(0,0,0,0.5);

	td {
		text-align: right;

		&:nth-child(2),&:nth-child(3) {
			text-align: left;
		}
	}
}

#messages-table > tbody > tr {
	height: inherit;

	> td {
		border-bottom: 1px solid #eeeeee;
		padding-left: 2px;
		padding-right: 2px;
		text-align: right;

		&:nth-child(2),&:nth-child(3) {
			text-align: left;
		}
	}
	> td + td {
		border-left: 1px solid #eeeeee;
	}

	&.focused {
		background-color: $hover-color;
	}
}

/* Charts */

#chart-objects {
	height: calc(100% - #{$periodheight});
	border-top: 1px solid $separator-color;
}

.sensor-img {
	padding-right: 4px;
}

.ser-color {
	height: 10px;
	border-radius: 5px;
	display: block;
}

/* Period selector */

.period-selector {
	width: 100%;
	height: $periodheight;
	padding: 0 0 0 7px;
	> table {
		height: 100%;
		width: 100%;
		> tbody > tr > td {
			padding: 2px 4px;
		}
	}

	.input-field {
		min-height: 30px;
	}

	.datepicker {
		text-align: center;
	}

	.mb-raised {
		height: 32px;
	}
}


.input-group {
	.input-field {
		min-height: 30px;
	}
}
/* Конвертор */

#fd-sens-tartable,
#fd-sens-linear {
	td {
		width: 86px;
		height: 21px;
		border: 1px solid $grey-400;
		input {
			border: none;
			width: 100%;
			height: 100%;
		}
	}
}

/* Zones table */
#zone-controls {
	width: 100%;
	padding: 6px 0;
}

#zone-group-div {
	width: auto;
}

#zone-table-container {
	height: calc(100% - 43px);
	overflow-y: auto;
}

/* Alerts table*/

#alert-table-container {
	height: 100%;
	overflow-y: auto;
}

#alert-table > tbody > tr {
	> td:nth-child(2) {
		width: 26px;
	}

	> td:nth-child(3) {
		padding-left: 5px;
	}

	span {
		background-image: url('../img/icons/alert_red.png');
		width: 16px;
		height: 16px;
	}

	&.alertclass-active span {
		background-image: url('../img/icons/alert_green.png');
	}
}


/* Catalogs */

.catalog-table > tbody > tr {
	> td {
		> span:nth-child(1) {
			float: left;
			width: 16px;
			height: 16px;
		}
	}
}

/* Tree */

.tree {
	list-style:none;

	ul {
		padding:0;
		list-style:none;
		margin: 0 0 0 6px;
		position: relative;
		display: none;

		ul {
			margin-left:.5em;
		}

		&:before {
			content:"";
			display:block;
			width:0;
			position:absolute;
			top:0;
			bottom:0;
			left:0;
			border-left:1px solid;
		}

		li:before {
			content:"";
			display:block;
			width:10px;
			height:0;
			border-top:1px solid;
			margin-top:-1px;
			position:absolute;
			top:1em;
			left:0;
		}
		li:last-child:before {
			background:#fff;
			height:auto;
			top:1em;
			bottom:0;
		}
	}
	li {
		//margin:0;
		//padding:0 0 0 10px;
		position:relative;

        >label {
          font-weight: normal;
          cursor: pointer;
          @include no-select;
          >span {
            width: 14px;
            height: 14px;
            display: inline-block;
            margin-right: 5px;
            vertical-align: sub;
            background:url(../img/icons/check.svg) no-repeat;
            background-size: cover;
            &.span-checked {
              background:url(../img/icons/check_blue.svg) no-repeat;
              background-size: cover;

            }
          }
        }
		> a {
			display: block;
			text-decoration: none;
			padding: 3px 20px 3px 10px;
			color: $grey-900;

			&:hover {
				text-decoration: none;
				background-color: $hover-color;
			}
		}

        &:not(.branch) {
          label {
            margin-left: 10px;
          }
        }

		&.branch {
			margin:0;
			padding:0 0 0 10px;

			> a {
				display: inline-block;
				width: 100%;
				padding-left: 0;
				border-left: none;
				margin: 0;

				/*&:hover {
					border-left: none;
				}*/
			}
		}

		button,
		button:active
		button:focus {
			border:none;
			background:transparent;
			margin:0;
			padding:0;
			outline: 0;
		}
	}

	i {
		cursor: pointer;
		padding-right: 2px;
	}

	li.expand > ul {
		display: block;
	}
}
.indicator {
	margin-right:5px;
}

/* Zones editor and Controlpoint editor*/

#zone-editor {
    display: block !important;
    height: auto !important;
    cursor: default;
	position: absolute;
	left: 46px;
	top: 49px;
	width: 260px;

	> .paper-content {
		padding: 12px 15px 0;
	}
}


#ze-groups-table {
  tr {
    display: none;
    td {
      i {
        font-size: 20px;
        opacity: 0.54;
        width: 20px;
      }
    }
  }
}

#ze-div-states {
	width: 100%;
	margin-top: 12px;

	label {
		margin-bottom: 10px;
	}
}


/* Alert-Panel */
#alert-dir-controls {
	width: 100%;
	padding: 3px 0;
}

#alert-his-controls {
	width: 100%;
}

#alert-his-content{
	height: 100%;
	overflow-y: auto;
	padding-left: 5px;
}

#alert-classes-list {
	padding-left: 7px;
}

#alertNotice {
	background-color: #ffffff;
	position: fixed;
	right: 100px;
	left: 100px;
	bottom: 10px;
	height: 150px;
	padding-bottom: 35px;
	z-index: 1000;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
	border-radius: 2px;
}

#alertNotice-header {
	font-weight: 500;
	height: 35px;
	width: 100%;
	border-bottom: 2px solid #F0F0F0;
}

#alertNotice-header td:first-child,
#alertNotice-table td:first-child{
	padding-left: 5px;
}

#alertNotice-content {
	overflow: auto;
	height: 100%
}

#alertNotice-table {
	width: 100%
}
#alertNotice-table td{
	overflow: hidden;
	word-wrap: normal;
}

/*- Master alert -*/
#addAlertDialog{
	padding: 0 5px;
}



#master-alert button{
	border-radius: 0;
}

#master-alert .timepicker input[type="text"]{
	width:60px;
}

#master-alert {
	li > input[type="radio"],
	li > input[type="checkbox"] {
		margin: 3px 4px 0 15px;
	}
}

#master-alert {
	li.branch > input[type="radio"],
	li.branch > input[type="checkbox"] {
		margin: 3px 4px 0 3px;
	}
}

.master-page button{
	font-size:12px;
}

#master-alert table tr span{
	display: none;
}

#master-alert table tr.empty span{
	display: block;
}

/*
#master-alert-cause-table{
	width: 100%;
}

#master-alert-cause-table tr{
	border: 1px solid #666666;
}

#master-alert-cause-table tr:hover,
#master-alert-cause-table .selected:hover,
#master-alert-cause-table input[type="text"]:hover,
#master-alert-cause-table .selected:hover input[type="text"]{
	background-color: #ccc;
	color: #ffffff;
}

#master-alert-cause-table input[type="text"],
.tf-hour-table input[type="text"]{
	border: 0;
	padding: 0;
	width: 100%;
}

#master-alert-cause-table .selected,
#master-alert-cause-table .selected input[type="text"],
.tf-hour-table .selected,
.tf-hour-table .selected input[type="text"]{
	background-color: #999;
	color: #ffffff;
}
*/

#master-alert-cause-table {
  width: 100%;

  tr {
    input {
      width: 100%;
      //border: solid 1px #cecfcf;
    }
  }
}

.pattern-settings-show {
  display: block;
}

#master-alert-generators-settings ul:first-child li a {
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
}

// Objects Edit Form

.hide-deleted .obj-deleted {
	display: none;
}

.sens-table {
	padding: 8px 16px;
	min-height: 48px;
	border-top: 1px solid $separator-color;

	.obj-deleted .item-text {
		text-decoration: line-through;
	}
}

.device-card {
	@include transition(all 0.2s ease 0s);
	cursor: pointer;

	&.obj-deleted > .list-item-action .item-text {
		text-decoration: line-through;
	}

	&.expand {
		margin-left: 0;
		margin-right: 0;
		border-radius: 0;
	}
}

// Cloud
.cloud-browser {
	padding: 0 !important;
}

.cloud-browser-main {
	height: 100%;
	width: 100%;
}

.cloud-browser-content {
	height: calc(100% - 48px);

	border-bottom: 1px solid $separator-color;
}

.cloud-browser-filename {
	height: 48px;

	display: flex;
	align-items: center;
	padding: 8px;

	i {
		width: 8px;
	}

	input[type="text"] {
		flex: 1 1 auto;
		border: 1px solid $grey-500;
	}
}

.cloud-browser-buttons {
	flex: 0 0 auto;
	padding: 8px;
	text-align: right;

	button {
		margin: 0 4px;
	}
}

.clouds-list {
	width: 170px;
	height: 100%;
	float: left;

	background-color: $grey-200;
	padding-top: 16px;
	//border-right: 1px solid $separator-color;

	.item {
		line-height: 40px;
		cursor: pointer;
		padding-left: 16px;

		&:hover {
			background-color: $hover-color;
		}

		&.selected {
			color: $darkblue-500;
			font-weight: bold;
			border-left: 6px solid;
			padding-left: 10px;
		}

		&.inactive > span {
			opacity: 0.4;
		}

		&.loading {
			.cloud-icon {
				background: transparent url("/img/loading.gif") no-repeat scroll 50% 50% !important;
			}
		}
	}
}

.cloud-icon {
	display: inline-block;
	width: 32px;
	height: 32px;
	vertical-align: middle;
	margin-right: 8px;
	background-position: center;
	background-repeat: no-repeat;
}

.cloud-content {
	float: right;
	width: calc(100% - 170px);
	height: 100%;
	border-left: 1px solid $separator-color;

	@include no-select();
}

.file-path {
	height: 24px;
	border-bottom: 1px solid $separator-color;
	display: flex;
	align-items: center;
	padding: 0 6px;

	span {
		cursor: pointer;
	}

	i {
		color: $grey-500;
	}
}

.file-list {
	height: calc(100% - 24px);
	overflow: auto;

	.pad-item {
		height: 20px;
	}
}

.file-item {
	line-height: 40px;
	padding-left: 16px;
	cursor: pointer;
	border-bottom: 1px solid $separator-color;
	white-space: nowrap;

	overflow: hidden;
	text-overflow: ellipsis;
	-o-text-overflow: ellipsis;

	.material-icons {
		vertical-align: middle;
		padding-right: 16px;
		color: $grey-600;
	}

	&.selected {
		color: #ffffff;
		background-color: $darkblue-300;

		.material-icons {
			color: #ffffff;
		}
	}
}

#tab-sett-clouds {
	.input-field {
		.service-name {
			left: 4px;
			position: relative;
			line-height: normal;
			display: inline;
		}

		&.active {
			.service-name {
				top: -4px;
			}
			.service-account {
				display: block;
				position: absolute;
				color: grey;
				left: 37px;
				top: 19px;
				font-size: 12px;
				line-height: normal;
			}
		}
	}
}

#list-clouds {
  padding: 8px 16px;
  min-height: 48px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);

  .input-field {
    .service-name {
      left: 4px;
      position: relative;
      line-height: normal;
      display: inline;
    }

    &.active {
      .service-name {
        top: -4px;
      }
      .service-account {
        display: block;
        position: absolute;
        color: grey;
        left: 37px;
        top: 19px;
        font-size: 12px;
        line-height: normal;
      }
    }
  }
}

// Orders
#orders-table {
	td:nth-child(5) {
		width: 24px;
	}
}




#repair-causes-tree {
  width: 60%;
  margin-top: 20px;
  max-height: 200px;
  overflow-y: auto;
}

#div-clouds {
  table {
    width: 100%;
    td, th {
      padding: 3px;
      border: solid 1px;
    }
  }
}

.repres-settings {
  .repres-date-type input {
    max-width: 140px;
  }

  .repres-interval input {
    width: 70px;
  }
  .dp-period input {
    width: 110px;
  }
}

.icons-mobj-form {
  max-height: 170px;
  overflow-y: auto;
  text-align: center;

  table {
    width: 100%;
    margin-bottom: 10px;
    td {
      text-align: center;
      padding: 3px;

      img {
        cursor: pointer;
      }
    }

    td:hover {
      background-color: $hover-color;
    }
  }
}

.obj-div-icon {
  overflow: visible;
	border-radius: 100%;

	&.selected {
		box-shadow: 0 0 8px 2px rgba(0,0,0,1);
	}

  .obj-shadow-icon {
    top: 80%;
    left: 2px;
    position: absolute;
  }

  .obj-course {
    position: absolute;
    height: 100%;
    width: 100%;

    &:after {
      content: '';
      position: absolute;
      left: 50%;
      border: 6px solid transparent;
      border-bottom-width: 8px;
      transform: translateX(-50%);
      bottom: calc(100% - 2px);
      display: block;
    }
  }

}

// state mObject marker on map
.obj-n0,
.obj-m1,
.obj-m2,
.obj-m3,
.obj-s0 {
	text-align: center;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
}

.obj-n0 {
	background-image: url('../img/markers/_n0.svg');

	.obj-course:after {
		border-bottom-color: #000000;
	}
}

.obj-m1 {
	background-image: url('../img/markers/_m1.svg');

	.obj-course:after {
		border-bottom-color: #26a766;
	}
}

.obj-m2 {
  background-image: url('../img/markers/_m2.svg');

  .obj-course:after {
    border-bottom-color: #ffa31a;
  }
}

.obj-m3 {
  background-image: url('../img/markers/_m3.svg');

  .obj-course:after {
    border-bottom-color: #e60000;
  }
}

.obj-s0 {
  background-image: url('../img/markers/_s0.svg');

  .obj-course:after {
    border-bottom-color: #0039e6;
  }
}

.obj-icon {
	position: absolute;
	left: 50%;
	top: 50%;
	width: 21px;
	height: 21px;
	background-size: 21px;
	transform: translate(-50%, -50%);
	background-position: center;
	background-repeat: no-repeat;
}

.obj-state-icon {
  position: absolute;
}

#ds-controls {
  padding: 5px 16px;
  width: 100%;

  #sd-objsubgroups-btn {
    width: 34px;
    height: 34px;
  }

  table {
    width: 100%;
    tr {
      td:not(:last-child) {
        padding-right: 10px;
      }
    }
  }
}

#ul-reports-role {
  max-height: 100%;
}


// Table FuelConsRates
#tab-cons100km, #tab-cons1hour {
  padding: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #aaaaaa;

  td {
     white-space: normal;
  }
}

//Zone group

#add-zone-in-group-div {
  display: none;

  .btn-add-zone-in-group {
    width: auto;

    span {
      margin-top: 2px;
      margin-left: 2px;
      margin-right: 1px;
      display: block;
      float: right;
    }
  }
}

#md-form-setting-zonegroup {
  overflow-y: hidden;
}

#all-group-zone, #curr-group-zone  {
  height: 100%;
  width: 49%;

  #curr-group-zone-table-container{
    overflow-y: scroll;
    margin-bottom: 13px;
    height: calc(100% - 125px);
  }
  #all-group-zone-table-container {
    overflow-y: scroll;
    margin-bottom: 13px;
    height: calc(100% - 181px);
  }
}


#all-group-zone {
  #all-zone-group-find {
    width: 100%;
    padding-bottom: 7px;

    #fd-zonegroup-find {
      min-height: 0px;
      padding-left: 13px;
      padding-top: 3px;
      width: 100%;

      input {
        width: 290px;
      }
    }
  }
}

/*** user ***/

.user-container {
  height: 100%;
  overflow: hidden;
}