@import "colors", "base";

//Оформление контента справки

//Контейнер контента
#user-guide-container {
  padding: 64px;
  overflow-y: auto;
}

//Глава справки
.chapter-ug {
  font-size: 22px;
  font-weight: 400;
}

//Под-глава справки
.subchapter-ug {
  font-size: 16px;
  font-weight: 500;
}

//Обычный текст
.text-ug {
  font-size: 14px;
}

//Дополнительное примечание к картинке
.addition-note-ug {
  font-size: 14px;
  margin-bottom: 10px;
  color: $grey-500;
  display: block;
}

//Маркерованый список
.ul-ug {
  font-size: 14px;
  margin: 0px 35px 15px 35px;
  list-style-type: disc;

  li {
    margin-bottom: 10px;
  }
}

.img-ug {
  display: block;
  margin-bottom: 20px;
  margin-right: 20px
}

//Список без маркеров, для описания пояснения картинок, шагов и т.д.
.ul-description-ug {
  font-size: 14px;
  margin-bottom: 15px;

  li {
    margin-bottom: 5px;
  }

  span {
    color: $blue-700;
  }

  li ul {
    padding-top: 7px;
  }
  li ul li {
    margin-left: 20px;
  }
}

//Контейнер для случаев когда картинка слева, текст - справа
.content-row-2 {
  img {
    float: left;
    padding-bottom: 10px;
  }
}

.div-clear {
  clear: both;
}

//Меню справки
#navigation-ug {
  color: $blue-700;
  padding: 32px;

  li {
    margin-bottom: 7px;

    label {
      margin: 0;
      padding: 2px 5px;
      font-size: 14px;
      font-weight: 600;
      width: 100%;
      cursor: pointer;
    }

    .selected {
      background-color: rgba(153,153,153,0.2);
    }

    label:hover {
      background-color: rgba(153,153,153,0.1);
    }
  }
  ul {
    padding-top: 7px;
  }
  ul li label {
    margin-left: 20px;
    font-weight: normal;
  }
}

//Подсветка найденных слов в справке
.highlight {
  background-color: #fff34d;
  -moz-border-radius: 5px; /* FF1+ */
  -webkit-border-radius: 5px; /* Saf3-4 */
  border-radius: 5px; /* Opera 10.5, IE 9, Saf5, Chrome */
  -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.7); /* FF3.5+ */
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.7); /* Saf3.0+, Chrome */
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.7); /* Opera 10.5+, IE 9.0 */
}

.highlight-current {
  background-color: #ffcf78;
  -moz-border-radius: 5px; /* FF1+ */
  -webkit-border-radius: 5px; /* Saf3-4 */
  border-radius: 5px; /* Opera 10.5, IE 9, Saf5, Chrome */
  -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.7); /* FF3.5+ */
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.7); /* Saf3.0+, Chrome */
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.7); /* Opera 10.5+, IE 9.0 */
}

#div-navigation-ug {
  position: relative;
  height: calc(100% - 170px);
  overflow-y: auto;
}

#about-ug {
  border-top: 3px solid rgb(238, 236, 238);
  padding: 20px;
  height: 170px;

  position: relative;
  margin-top: -170pxem;
}

